import { UserPermissionEnum } from '@moxga/services'
import { Button } from '@moxga/ui-components'
import withPermission from 'hoc/withPermission'
import { Link } from 'react-router-dom'

const AddNewCountryButton = () => {
  return (
    <Link to={'/news/country/create'}>
      <Button type="button" style={{ height: '39px' }}>
        Add New Country
      </Button>
    </Link>
  )
}

export default withPermission({
  noPermissionsRenderNothing: true,
  requiredPermissions: [UserPermissionEnum.NEWS_COUNTRY_CREATE],
})(AddNewCountryButton)
