import { Alert, Button, Input } from '@moxga/ui-components'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import {
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'
import { compose, withHandlers, withProps } from '@moxga/utils'
import { ICreateNewsCountryBody, NewsService } from '@moxga/services'

const { yupResolver } = require('@hookform/resolvers/yup')

interface Props {
  register: UseFormRegister<ICreateNewsCountryBody>
  onSubmitForm: () => {}
  handleSubmit: UseFormHandleSubmit<ICreateNewsCountryBody>
  errors: {
    name: {
      message: string
    }
    code: {
      message: string
    }
  }
  isUpdateMode: boolean
}

const CreateEditNewsCountryForm: React.FC<React.PropsWithChildren<Props>> = ({
  register,
  onSubmitForm,
  errors,
  handleSubmit,
  isUpdateMode,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-4">
      <Input
        placeholder="Thailand"
        config={{ ...register('name') }}
        label="Country"
        descriptionText={errors.name?.message}
        isError={!!errors.name?.message}
      />
      <Input
        placeholder="th"
        config={{ ...register('code') }}
        label="ISO Code"
        descriptionText={errors.code?.message}
        isError={!!errors.code?.message}
      />
      <div className="flex justify-between">
        <Link to={'/news/country'}>
          <Button type="button" variant="outlined">
            Back
          </Button>
        </Link>
        <Button type="submit">{isUpdateMode ? 'Update' : 'Create'}</Button>
      </div>
    </form>
  )
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('จำเป็นต้องกรอกชื่อประเทศ'),
  code: Yup.string().required('จำเป็นต้องกรอกรหัสประเทศ'),
})

interface ICountryData extends ICreateNewsCountryBody {
  id: number
  label: string
}

export default compose(
  withProps(() => {
    const navigate = useNavigate()
    const formOptions = { resolver: yupResolver(validationSchema) }
    const {
      register,
      setValue,
      handleSubmit,
      formState: { errors },
      setError,
    } = useForm<ICreateNewsCountryBody>(formOptions)

    return {
      navigate,
      register,
      setValue,
      handleSubmit,
      errors,
      setError,
    }
  }),
  withProps(
    ({
      countryId,
      countryData,
      setValue,
    }: {
      countryId: number | undefined
      countryData: ICountryData | null
      setValue: UseFormSetValue<ICreateNewsCountryBody>
    }) => {
      const isUpdateMode = !!countryId

      if (isUpdateMode && countryData) {
        setValue('name', countryData?.label)
        setValue('code', countryData?.code)
      }

      return {
        isUpdateMode,
        countryData,
      }
    }
  ),
  withHandlers({
    onSubmitForm:
      ({
        countryId,
        navigate,
        setError,
      }: {
        countryId: number
        navigate: NavigateFunction
        setError: UseFormSetError<ICreateNewsCountryBody>
      }) =>
      async (data: ICreateNewsCountryBody) => {
        if (!countryId) {
          const result = await NewsService.createCountryCode(data)

          // success case
          if (result.success) {
            navigate('/news/country')
            return
          }
          // failed case
          if (!result.success) {
            if (result.errors) {
              setError('name', { message: result.errors['name'] })
              setError('code', { message: result.errors['code'] })
            } else {
              // TODO: show toast
              toast.custom(<Alert type="danger">{result.message}</Alert>)
            }
          }
        } else {
          const result = await NewsService.editCountryCode(countryId, data)
          if (result.success) {
            navigate('/news/country')
            return
          }

          if (!result.success) {
            if (result.errors) {
              setError('name', { message: result.errors['name'] })
              setError('code', { message: result.errors['code'] })
            } else {
              // TODO: show toast
              toast.custom(<Alert type="danger">{result.message}</Alert>)
            }
          }
        }
      },
  })
)(CreateEditNewsCountryForm)
