import { ISelect, TInputColor, TInputSize } from '../../interfaces/input'

import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'
import { useMemo } from 'react'

const Select: React.FC<React.PropsWithChildren<ISelect>> = ({
  label,
  captionText,
  descriptionText,
  size = 'md',
  color = 'primary',
  isSuccess = false,
  isError = false,
  disabled = false,
  fullWidth = false,
  style,
  options,
  config,
  value,
  onChange,
  noSelectValue,
}) => {
  let selectStyleProps = useMemo(() => {
    return {
      size,
      color,
      isSuccess,
      isError,
      fullWidth,
    }
  }, [size, color, isSuccess, isError, fullWidth])

  return (
    <SelectWrapperStyle {...selectStyleProps}>
      {(label || captionText) && (
        <div className="label-wrapper">
          <label className="label">{label}</label>
          <span className="caption-text">{captionText}</span>
        </div>
      )}
      <select
        className="select"
        disabled={disabled}
        style={style}
        {...config}
        onChange={e => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
      >
        <option disabled={true} selected={!value} value={noSelectValue || ''}>
          {label || 'Please select'}
        </option>
        {options.map((option, index) => {
          return (
            <option
              key={index}
              selected={option.value === value}
              value={option.value}
            >
              {option.label}
            </option>
          )
        })}
      </select>
      <span className="description-text">{descriptionText}</span>
    </SelectWrapperStyle>
  )
}

const selectStyleSizes = {
  sm: {
    'font-size': '0.8rem',
    padding: '8px 15px',
  },
  md: {
    'font-size': '1rem',
    padding: '8px 15px',
  },
  lg: {
    'font-size': '1.2rem',
    padding: '14px 15px',
  },
}

interface SelectWrapperStyleProps {
  size: TInputSize
  color: TInputColor | string
  isSuccess: boolean
  isError: boolean
  fullWidth: boolean
}

const SelectWrapperStyle = styled.div<SelectWrapperStyleProps>`
  position: relative;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  .label-wrapper {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 1px solid
      ${props =>
        props.isSuccess
          ? props.theme.color?.success || defaultTheme.color?.success
          : props.isError
          ? props.theme.color?.danger || defaultTheme.color?.danger
          : props.theme?.['color-system']?.['border-input'] ||
            defaultTheme['color-system']?.['border-input']};
    border-radius: 5px;

    ${props => selectStyleSizes[props.size]}

    ::-webkit-input-placeholder {
      color: ${props =>
        props.theme?.['color-system']?.['placeholder'] ||
        defaultTheme['color-system']?.['placeholder']};
    }

    :-ms-input-placeholder {
      color: ${props =>
        props.theme?.['color-system']?.['placeholder'] ||
        defaultTheme['color-system']?.['placeholder']};
    }

    ::placeholder {
      color: ${props =>
        props.theme?.['color-system']?.['placeholder'] ||
        defaultTheme['color-system']?.['placeholder']};
    }
  }

  .select:focus {
    outline: 1px solid
      ${props =>
        props.theme?.color?.[props.color] ||
        defaultTheme.color?.[props.color as TInputColor] ||
        props.color};
  }

  .description-text {
    margin-top: 6px;
    color: ${props =>
      props.isSuccess
        ? props.theme.color?.success || defaultTheme.color?.success
        : props.isError
        ? props.theme.color?.danger || defaultTheme.color?.danger
        : props.theme?.['color-system']?.['placeholder'] ||
          defaultTheme['color-system']?.['placeholder']};
  }
`

export default Select
