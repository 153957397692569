import { Alert } from '@moxga/ui-components'
import { useEffect } from 'react'
import toast from 'react-hot-toast'

const useErrorToaster = (error: string | undefined | null) => {
  useEffect(() => {
    if (error) {
      console.error('useErrorToaster', error)
      toast.custom(<Alert type="danger">{error}</Alert>)
    }
  }, [error])

  return {}
}

export default useErrorToaster
