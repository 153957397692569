import {
  ModalProvider,
  ThemeProvider,
  defaultTheme,
} from '@moxga/ui-components'

import AppStore from 'store/AppStore'
import { AppStoreContext } from 'store/AppStore/AppStore'
import Routes from 'routes'
import { Toaster } from 'react-hot-toast'

const App = () => {
  const appStore = AppStore()
  return (
    <AppStoreContext.Provider value={appStore}>
      <ThemeProvider theme={defaultTheme}>
        <ModalProvider>
          <Toaster position={'bottom-left'} />
          <Routes />
        </ModalProvider>
      </ThemeProvider>
    </AppStoreContext.Provider>
  )
}

export default App
