import { IUser } from '@moxga/services'
import React, { createContext, useContext, useEffect } from 'react'
import { UserBackofficeService } from '@moxga/services'
import { useAsync } from '@moxga/utils'

export interface IUserDetailContext {
  userDetail: IUser | null
  isLoading: boolean
  hasUserData: boolean
  refetch: () => void
}

export const DefaultUserDetailContext: IUserDetailContext = {
  userDetail: null,
  isLoading: true,
  hasUserData: false,
  refetch: () => {},
}

export const UserDetailContext = createContext<IUserDetailContext>(
  DefaultUserDetailContext
)

export const UserDetailContextProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ userId: string }>>> = ({ userId, children }) => {
  const { isLoading, data, execute } = useAsync({
    asyncFn: () => UserBackofficeService.detail(userId),
    isImmediate: true,
    initialData: null,
  })

  const contextValues = {
    userDetail: data,
    hasUserData: !!data,
    isLoading,
    refetch: execute,
  }

  return (
    <UserDetailContext.Provider value={contextValues}>
      {children}
    </UserDetailContext.Provider>
  )
}

export const useUserDetailContext = () => useContext(UserDetailContext)
