import { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
}
const Box: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  return <BoxStyle>{children}</BoxStyle>
}

const BoxStyle = styled.div`
  background: white;
  border-radius: 6px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
`

export default Box
