import { Box } from '@moxga/ui-components'

import { UserPermissionEnum } from '@moxga/services'
import withAuth from 'hoc/withAuth'
import { compose } from '@moxga/utils'
import withPermission from 'hoc/withPermission'
import CreateEditNewsCountryForm from './components/CreateEditNewsCountryForm'

const CreateNewsCountryPage = () => {
  return (
    <Box>
      <div className="p-5">
        <CreateEditNewsCountryForm />
      </div>
    </Box>
  )
}

export default compose(
  withAuth,
  withPermission({
    requiredPermissions: [UserPermissionEnum.NEWS_COUNTRY_CREATE],
  })
)(CreateNewsCountryPage)
