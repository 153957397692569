import React, { ReactNode } from 'react'

import MenuList from './MenuList'
import styled from 'styled-components'

const { version } = require('../../../../package.json')

interface Props {
  logo: string | ReactNode
  menu: {
    icon?: ReactNode
    label: string
    href?: string
    prefix?: string
    subMenu?: {
      icon?: ReactNode
      label: string
      href: string
    }[]
  }[]
}

const Sidebar: React.FC<React.PropsWithChildren<Props>> = ({ logo, menu }) => {
  return (
    <SidebarStyle>
      <div className="logo-wrapper">
        {typeof logo === 'string' ? (
          <img src={logo} className="logo" alt="logo" />
        ) : (
          logo
        )}
      </div>
      <MenuList menu={menu} />
      <div className="mt-10 text-center opacity-70 text-xs">
        Version {version}
      </div>
    </SidebarStyle>
  )
}

const SidebarStyle = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding: 25px;
  background-color: white;

  .logo-wrapper {
    margin-bottom: 28px;

    .logo {
      height: 50px;
      width: 100%;
      object-fit: contain;
    }
  }
`
export default Sidebar
