import {
  Alert,
  Box,
  Button,
  CopyURL,
  DatePicker,
  Editor,
  ImageUpload,
  Input,
  Select,
  TagList,
  Tiktok,
  Video,
  useModal,
  Reference,
} from '@moxga/ui-components'
import {
  INewsCategory,
  INewsCountry,
  INewsDetailForm,
  ITag,
  NewsService,
  INewsReferences,
} from '@moxga/services'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import toast from 'react-hot-toast'
import withAuth from 'hoc/withAuth'
import useNewsForm from '../../../../hooks/useNewsForm'
import { BackspaceIcon } from '@heroicons/react/outline'
import dayjs from 'dayjs'

const EditNewsPage = () => {
  const navigate = useNavigate()
  let { groupId, newsId } = useParams()
  const [news, setNews] = useState<INewsDetailForm | null>(null)
  const [newsDefault, setNewsDefault] = useState<INewsDetailForm | null>(null)
  const [tags, setTags] = useState<string[]>([])
  const [{ countries, loading, categories }] = useNewsForm(groupId!)
  const [newsReferences, setNewsReferences] = useState<INewsReferences[]>([])
  const [invalidField, setInvalidField] = useState<{
    title?: boolean
    markdown?: boolean
    slug?: boolean
    contentHtml?: boolean
  } | null>(null)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const result = await NewsService.journalist.detailNews(
      groupId as string,
      parseInt(newsId as string)
    )
    if (result.success) {
      //success case
      if (result.data.tags) {
        setTags(result.data.tags.map(t => t.name))
      }
      if (result.data.references) {
        setNewsReferences(
          result.data.references.map(({ label, link }) => ({ label, link }))
        )
      }
      setNewsDefault(result.data)
      setNews(result.data)
    } else {
      //failed case
      toast.custom(<Alert type="danger">{result.message}</Alert>)
    }
  }

  const onChangeNews = (name: string, value: string | number | File) => {
    if (news) {
      setNews({ ...news, [name]: value })
    }
  }

  const onEditNews = async () => {
    if (news) {
      let data = new FormData()
      data.append('references', JSON.stringify(newsReferences))
      if (typeof news.coverImageUrl === 'object') {
        data.append('cover', news.coverImageUrl)
      }
      if (typeof news.squareImageUrl === 'object') {
        data.append('square', news.squareImageUrl)
      }
      data.append('title', news.title)
      if (news.metaTitle) {
        data.append('metaTitle', news.metaTitle)
      }
      data.append('markdown', news.markdown)
      if (news.metaDescription) {
        data.append('metaDescription', news.metaDescription)
      }
      if (news.categoryId) {
        data.append('categoryId', news.categoryId)
      } else {
        toast.custom(<Alert type="danger">กรุณาเลือกประเภท</Alert>)
        return
      }
      if (news.countryCode) {
        data.append('countryCode', news.countryCode + '')
      } else {
        toast.custom(<Alert type="danger">กรุณาเลือกประเทศ</Alert>)
        return
      }
      data.append(
        'publishedDate',
        dayjs(news.publishedDate).format('YYYY-MM-DDTHH:mm:ssZ')
      )
      if (news.html) {
        data.append('html', news.html)
      } else {
        toast.custom(<Alert type="danger">กรุณากรอกเนื้อหาข่าว</Alert>)
        return
      }
      if (tags.length > 0) {
        data.append('tags', (tags as string[]).join(','))
      } else {
        toast.custom(<Alert type="danger">กรุณาใส่แท็ก</Alert>)
      }
      if (news.videoUrl) {
        data.append('videoUrl', news.videoUrl as string)
      }
      if (news.tiktokUrl) {
        data.append('tiktokUrl', news.tiktokUrl as string)
      }
      data.append('group_id', groupId as string)
      data.append('news_id', newsId as string)
      const result = await NewsService.journalist.editNews(
        groupId as string,
        parseInt(newsId as string),
        data
      )
      if (result.success) {
        toast.custom(<Alert type="success">แก้ไขข่าวสารสำเร็จ</Alert>)
        navigate(`/journalist/group/${groupId}/news`)
      } else {
        toast.custom(<Alert type="danger">{result.message}</Alert>)
      }
    }
  }
  // TODO: remove duplicate code
  // hot fix
  const imageHandler = async (file: Blob) => {
    if (file && /^image\//.test(file.type)) {
      // TODO: validate file size.
      const formData = new FormData()
      formData.append('image', file)

      const res = await NewsService.journalist.uploadImage(
        groupId as string,
        formData
      )

      if (res.success) {
        const url = res?.data?.url as string

        toast.success('Uploaded image successfully')
        return url
      } else {
        toast.error(`Upload image error. ${res.message}`)
        return ''
      }
    } else {
      toast.error('You could only upload images.')
      return ''
    }
  }
  const handleGenerateReference = (references: INewsReferences[]) => {
    setNewsReferences(references)
  }

  return (
    <Box>
      <div className="p-5">
        <div className={'mb-5'}>
          <Link to={`/journalist/group/${groupId}/news`}>
            <Button variant="outlined" size="sm">
              <BackspaceIcon className="ico h-4 w-4 mr-1" /> Back
            </Button>
          </Link>
        </div>
        {loading ? (
          <div className="text-center p-[20px]">Loading...</div>
        ) : (
          <>
            {news && newsDefault && (
              <div className="space-y-6">
                <div className="block space-y-6 md:space-y-0 md:grid md:grid-cols-2 md:gap-6">
                  <CopyURL label="Full URL" value={`${news.fullUrl}`} />
                  <CopyURL label="Short URL" value={`${news.shortUrl}`} />
                </div>

                <div className="block space-y-6 md:space-y-0 md:flex md:justify-between">
                  <div className="w-full md:w-[29.7%]">
                    <ImageUpload
                      onChange={(file: File) =>
                        onChangeNews('squareImageUrl', file)
                      }
                      label={'1:1 Cover Image'}
                      defaultImage={news.squareImageUrl || ''}
                      ratio={{ width: 1, height: 1 }}
                    />
                  </div>
                  <div className="w-full md:w-[52.8%]">
                    <ImageUpload
                      onChange={(file: File) =>
                        onChangeNews('coverImageUrl', file)
                      }
                      label={'16:9 Cover Image'}
                      defaultImage={news.coverImageUrl || ''}
                    />
                  </div>
                  <div className="w-full md:w-[16.5%]">
                    <Video
                      label="Video"
                      videoProps={{
                        url: (newsDefault?.videoUrl as string) || '',
                      }}
                    />
                  </div>
                </div>

                <div className="block space-y-6 md:space-y-0 md:grid grid-cols-2 gap-6 ">
                  <Tiktok
                    label="TIKTOK URL"
                    url={news.tiktokUrl}
                    urlDefault={newsDefault.tiktokUrl}
                    onChangeTiktokURL={url => onChangeNews('tiktokUrl', url)}
                  />
                  <Input
                    label="Video URL"
                    config={{
                      value: (news.videoUrl as string) || '',
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeNews('videoUrl', e.target.value),
                    }}
                    fullWidth
                    placeholder="https://cf.ikizzy.com/assets/media/demo.mp4"
                  />
                </div>
                <Input
                  label="Title"
                  config={{
                    value: (news.title as string) || '',
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeNews('title', e.target.value),
                  }}
                  fullWidth
                  placeholder="Title"
                />
                <Input
                  label="Meta Title (Optional for SEO)"
                  config={{
                    value: (news.metaTitle as string) || '',
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeNews('metaTitle', e.target.value),
                  }}
                  fullWidth
                  placeholder="Meta Title (Optional for SEO)"
                />
                <Input
                  label="Markdown"
                  config={{
                    value: (news.markdown as string) || '',
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeNews('markdown', e.target.value),
                  }}
                  fullWidth
                  placeholder="Markdown"
                />
                <Input
                  label="Meta Description (Optional for SEO)"
                  config={{
                    value: (news.metaDescription as string) || '',
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeNews('metaDescription', e.target.value),
                  }}
                  fullWidth
                  placeholder="Title"
                />
                <div className="block space-y-6 md:space-y-0 md:grid grid-cols-2 gap-6 ">
                  {categories && (
                    <Select
                      label="Category"
                      value={news.categoryId}
                      onChange={(value: string | number) =>
                        onChangeNews('categoryId', value)
                      }
                      options={categories.map(category => {
                        return {
                          label: category.name,
                          value: category.id,
                        }
                      })}
                    />
                  )}
                  {countries && (
                    <Select
                      label="Country"
                      value={news.countryCode}
                      onChange={(value: string | number) =>
                        onChangeNews('countryCode', value as number)
                      }
                      options={countries.map(country => {
                        return {
                          label: country.label,
                          value: country.id,
                        }
                      })}
                    />
                  )}
                  <DatePicker
                    label="Publish at"
                    onChange={(date: string) =>
                      onChangeNews('publishedDate', date)
                    }
                    value={news.publishedDate.toString()}
                    fullWidth
                  />
                </div>
                <div>
                  <Editor
                    label="Content"
                    value={news.html}
                    onChange={(html: string) => onChangeNews('html', html)}
                    imageHandler={imageHandler}
                  />
                </div>
                <div>
                  <TagList
                    tags={tags || []}
                    onSetTags={(tagsValue: string[]) => setTags(tagsValue)}
                    label="Tags"
                    html={news.html}
                  />
                </div>
                <div>
                  <Reference
                    references={newsReferences || []}
                    onGenReferences={handleGenerateReference}
                    label="Reference Link"
                  />
                </div>
                <div className="flex justify-between">
                  <Link to={`/journalist/group/${groupId}/news`}>
                    <Button variant="outlined">Back</Button>
                  </Link>
                  <Button onClick={onEditNews}>Save</Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  )
}

export default withAuth(EditNewsPage)
