import { Badge, Box, Input, Pagination, Table } from '@moxga/ui-components'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  getPermissionName,
  IPaginationResponse,
  IUser,
  UserBackofficeService,
  UserPermissionEnum,
} from '@moxga/services'

import axios from 'axios'
import withAuth from 'hoc/withAuth'
import { compose, useAsync, withHandlers, withProps } from '@moxga/utils'
import withPermission from 'hoc/withPermission'
import EditUserButton from './components/EditUserButton'
import { PAGE_LIMIT } from 'constant'

interface Props {
  isLoading: boolean
  handleSearch: () => void
  search: string
  users: IPaginationResponse<IUser>
  handlePageChange: (nextPage: number) => void
}

const UserPage = ({
  isLoading,
  handleSearch,
  search,
  users,
  handlePageChange,
}: Props) => {
  return (
    <Box>
      <div className="p-[20px] flex space-x-4 justify-end items-center">
        <span className="flex items-center">
          <span className="mr-4">Search: </span>
          <div>
            <Input
              placeholder="ค้นหา"
              config={{
                onChange: handleSearch,
                value: search,
              }}
            />
          </div>
        </span>
      </div>
      <Table
        headers={[
          {
            label: 'email',
            key: 'email',
          },
          {
            key: 'username',
          },
          {
            label: 'Display name',
            key: 'displayName',
          },

          {
            label: 'role',
            key: 'permission',
            render(value) {
              return (
                <Badge
                  size="sm"
                  variant={
                    (value & UserPermissionEnum.ADMINISTRATOR) ===
                    UserPermissionEnum.ADMINISTRATOR
                      ? `danger`
                      : `info`
                  }
                >
                  {getPermissionName(value)}
                </Badge>
              )
            },
          },
          {
            label: '-',
            key: 'id',
            thStyle: {
              textAlign: 'center',
            },
            render(value) {
              return <EditUserButton userId={value} />
            },
          },
        ]}
        data={users?.data || []}
        isLoading={isLoading}
      />
      <Pagination
        totalPage={users?.totalPages as number}
        onChange={handlePageChange}
      />
    </Box>
  )
}

export default compose(
  withAuth,
  withPermission({ requiredPermissions: [UserPermissionEnum.USER_SEARCH] }),
  withProps(() => {
    const [search, setSearch] = useState<string>('')
    const [currentPage, setCurrentPage] = useState(1)
    const searchDebounceTimeout = useRef<NodeJS.Timeout | null>(null)
    const prevSearch = useRef<string>('')

    const {
      data: users,
      execute,
      isLoading,
      error,
    } = useAsync({
      asyncFn: () =>
        UserBackofficeService.search({
          search,
          limit: PAGE_LIMIT,
          page: currentPage,
        }),
      isImmediate: true,
      initialData: {
        data: [],
        totalPages: 0,
        firstPage: true,
        lastPage: true,
        currentPage: 1,
      },
    })

    useEffect(() => {
      if (users.currentPage !== currentPage) {
        execute()
      }
    }, [currentPage, execute, users])

    useEffect(() => {
      if (!search && !prevSearch.current) {
        return
      }

      if (searchDebounceTimeout.current) {
        clearTimeout(searchDebounceTimeout.current)
      }

      if (prevSearch.current !== search) {
        searchDebounceTimeout.current = setTimeout(() => {
          prevSearch.current = search
          execute()
        }, 300)
      }

      return () => {
        searchDebounceTimeout.current &&
          clearTimeout(searchDebounceTimeout.current)
      }
    }, [search, execute])

    return {
      isLoading,
      users,
      error,
      search,
      setSearch,
      setCurrentPage,
    }
  }),
  withHandlers({
    handleSearch:
      ({ setSearch }: { setSearch: (v: string) => void }) =>
      async (e: ChangeEvent<HTMLInputElement>) => {
        await setSearch(e.target.value)
      },
    handlePageChange:
      ({ setCurrentPage }: { setCurrentPage: (v: number) => void }) =>
      (nextPage: number) => {
        setCurrentPage(nextPage)
      },
  })
)(UserPage)
