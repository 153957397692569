const useGanerateTagFormContent = () => {
  const onGanerateTagFormContent = (contentHtml: string) => {
    let tagsProps = [];
    let isBreak = false;
    let contentFormat = contentHtml.replace(/(<([^>]+)>)/gi, " ");
    contentFormat = contentFormat.replaceAll("&nbsp;", " ");
    while (!isBreak) {
      let tagPosition = contentFormat.indexOf("#");
      if (tagPosition !== -1) {
        contentFormat = contentFormat.substring(
          tagPosition,
          contentFormat.length
        );
        let stopPosition;
        let stopPosition1 = contentFormat
          .substring(1, contentFormat.length + 1)
          .indexOf("#");
        let stopPosition2 = contentFormat
          .substring(1, contentFormat.length + 1)
          .indexOf(" ");
        if (stopPosition1 !== -1 && stopPosition2 !== -1) {
          if (stopPosition1 < stopPosition2) {
            stopPosition = stopPosition1;
          } else {
            stopPosition = stopPosition2;
          }
          let tagProps = contentFormat.substring(1, stopPosition + 1);
          tagProps = tagProps.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            ""
          );
          tagProps = tagProps.replace(" ", "");
          if (tagProps) {
            if (tagsProps.filter((tag) => tag === tagProps).length === 0) {
              tagsProps.push(tagProps);
            }
          }
          contentFormat = contentFormat.substring(
            stopPosition + 1,
            contentFormat.length
          );
        } else {
          if (stopPosition1 !== -1 || stopPosition2 !== -1) {
            if (stopPosition1 !== -1) {
              stopPosition = stopPosition1;
            } else {
              stopPosition = stopPosition2;
            }
            let tagProps = contentFormat.substring(1, stopPosition + 1);
            tagProps = tagProps.replace(
              /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
              ""
            );
            tagProps = tagProps.replace(" ", "");
            if (tagProps) {
              if (tagsProps.filter((tag) => tag === tagProps).length === 0) {
                tagsProps.push(tagProps);
              }
            }
            contentFormat = contentFormat.substring(
              stopPosition + 1,
              contentFormat.length
            );
          } else {
            let tagProps = contentFormat.substring(1, contentFormat.length);
            tagProps = tagProps.replace(
              /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
              ""
            );
            tagProps = tagProps.replace(" ", "");
            if (tagProps) {
              if (tagsProps.filter((tag) => tag === tagProps).length === 0) {
                tagsProps.push(tagProps);
              }
            }
            isBreak = true;
          }
        }
      } else {
        isBreak = true;
      }
    }
    return tagsProps;
  };
  return { onGanerateTagFormContent };
};

export default useGanerateTagFormContent;
