import { fetcher } from '../libs/fetcher'
import { ITokenResponse, Response } from '../types/response'
import { IUser } from '../types'
import {
  IResetPasswordBody,
  ISignInBody,
  ISignInWithFacebook,
  ISignInWithGoogle,
  ISignUpBody,
} from '../types/request'

export const AuthService = {
  me() {
    return fetcher.get<IUser>(`/api/auth/me`)
  },

  signIn(data: ISignInBody) {
    return fetcher.post<ITokenResponse>(`/api/auth/sign-in`, data)
  },

  signUp(data: ISignUpBody) {
    return fetcher.post<ITokenResponse>(`/api/auth/sign-up`, data)
  },
  signInWithFacebook(data: ISignInWithFacebook) {
    return fetcher.post<ITokenResponse>(
      `/api/auth/social/sign-in-facebook`,
      data
    )
  },
  signInWithGoogle(data: ISignInWithGoogle) {
    return fetcher.post<ITokenResponse>(`/api/auth/social/sign-in-google`, data)
  },
  refreshToken(refreshToken: string) {
    return fetcher.post<ITokenResponse>(`/api/auth/refresh-token`, {
      token: refreshToken,
    })
  },
  /*
	actionURL = reset password page url
	 */
  forgotPassword({ actionURL, email }: { actionURL: string; email: string }) {
    return fetcher.post<{}>(`/api/auth/forgot-password`, {
      actionURL,
      email,
    })
  },
  resetPassword(data: IResetPasswordBody) {
    return fetcher.post<{}>(`/api/auth/reset-password`, data)
  },
}
