import {
  Badge,
  Box,
  Button,
  Pagination,
  Table,
  useModal,
} from '@moxga/ui-components'
import {
  IJournalistGroupItem,
  INewsListItem,
  IPaginationResponse,
  NewsJournalistClassEnum,
  NewsService,
  NewsStatusEnum,
} from '@moxga/services'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import withAuth from 'hoc/withAuth'
import { useAppStoreContext } from '../../../../store/AppStore/AppStore'
import toast from 'react-hot-toast'
import ApprovalModal from '../components/ApprovalModal'

function RenderError({ message }: { message: string }) {
  return (
    <div>
      <p>error {message}</p>
    </div>
  )
}

function Skeleton() {
  return (
    <Box>
      <div className={'bg-gray-100 animate-pulse w-full h-[100px]'}></div>
      <div className={'bg-gray-100 mt-10 animate-pulse w-full h-[500px]'}></div>
    </Box>
  )
}

const JournalistNewsPage = () => {
  const modal = useModal()
  const navigate = useNavigate()
  const { groupId } = useParams<{ groupId: string }>()
  const { user } = useAppStoreContext()
  const [news, setNews] = useState<IPaginationResponse<INewsListItem>>()
  const [loading, setLoading] = useState(true)
  const [isError, setError] = useState<string | null>(null)
  const [currentPage, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const [me, setMe] = useState<IJournalistGroupItem | undefined>()

  useEffect(() => {
    fetchData()
  }, [search, currentPage])
  const fetchData = () => {
    Promise.all([
      NewsService.journalist.detailGroup(groupId!).then(r => {
        if (!r.success) {
          setError(r.message)
          return
        }
        setMe(r.data)
      }),
      NewsService.journalist
        .listNews(groupId!, {
          page: currentPage,
          limit: 20,
          search,
        })
        .then(r => {
          if (!r.success) {
            setError(r.message)
            return
          }
          setNews(r.data)
        }),
    ]).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    // TODO: move this into appContext or something
    document
      .getElementsByClassName(
        'w-full h-screen overflow-y-auto lg:w-[calc(100%_-_260px)]'
      )[0]
      .scrollTo({
        top: 0,
        behavior: 'smooth',
      })
  }, [search, currentPage])

  const onApprove = async (newsId: number, nextApproveText: string) => {
    modal.onOpenModal({
      header: <div>อนุมัติข่าว</div>,
      body: (
        <ApprovalModal
          newsId={newsId}
          groupId={groupId!}
          nextApproveText={nextApproveText}
          refetch={() => {
            fetchData()
          }}
        />
      ),
    })
  }

  if (!groupId) {
    return null
  }

  if (isError) {
    return <RenderError message={isError || `unknown error message`} />
  }
  if (loading || !user || !me) {
    return <Skeleton />
  }

  return (
    <Box>
      <div className="flex justify-between space-x-4 p-4">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate(`/journalist`)}
        >
          ย้อนกลับ
        </Button>

        <div>
          {
            // TODO search box
            //
          }
          <Link to={`create`}>
            <Button>เพิ่มข่าว</Button>
          </Link>
        </div>
      </div>
      <Table<INewsListItem>
        headers={[
          {
            label: 'ไตเติล',
            key: 'title',
            thStyle: {
              textAlign: 'left',
            },
            render: value => <div>{String(value).substring(0, 25)}...</div>,
          },
          {
            label: 'รูปปก',
            key: 'coverImageUrl',
            render: (value, _, item) => (
              <img
                src={value}
                alt={item.title}
                className="h-[60px] rounded-md"
              />
            ),
          },
          {
            label: 'สถานะ',
            key: 'statusText',
            render(value, _, item) {
              // head and audit can approve
              if (
                me.me?.level &&
                me.me?.level <= NewsJournalistClassEnum.Professional &&
                (!item.isApprovedLifeTime || !item.isApprovedPermanent)
              ) {
                let nextApproveText = `อนุมัติ 24 ชั่วโมง`
                if (!item.isApprovedPermanent && item.isApprovedLifeTime) {
                  nextApproveText = 'อนุมัติถาวร'
                }

                return (
                  <Button
                    size="sm"
                    color="secondary"
                    variant="outlined"
                    onClick={() => onApprove(item.id, nextApproveText)}
                  >
                    {item.approvalText}
                  </Button>
                )
              }
              let variant:
                | 'primary'
                | 'secondary'
                | 'success'
                | 'danger'
                | 'warning'
                | 'info' = 'success'
              switch (item.status) {
                case NewsStatusEnum.DRAFT:
                  variant = 'secondary'
                  break
                case NewsStatusEnum.HIDDEN:
                  variant = 'danger'
              }
              return (
                <Badge size="sm" variant={variant}>
                  {value}
                </Badge>
              )
            },
          },
          {
            label: 'Published',
            key: 'publishedAt',
            render: value => (
              <span>{dayjs(value).format('DD/MM/YY HH:mm')}</span>
            ),
          },
          {
            label: 'Created',
            key: 'createdAt',
            render: value => (
              <span>{dayjs(value).format('DD/MM/YY HH:mm')}</span>
            ),
          },
          {
            label: 'Actions',
            key: 'id',
            render: value => (
              <Link to={`${value}/edit`}>
                <Button>Edit</Button>
              </Link>
            ),
          },
        ]}
        data={news?.data || []}
      />
      <Pagination
        totalPage={news?.totalPages || 0}
        onChange={page => {
          setPage(page)
        }}
      />
    </Box>
  )
}
export default withAuth(JournalistNewsPage)
