import React, { useState } from 'react'

import Button from '../Button'
import Swal from 'sweetalert2'
import Tag from './Tag'
import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'
import useGanerateTagFormContent from './useGanerateTagFormContent'

interface Props {
  tags: string[]
  label: string
  onSetTags: (tags: string[]) => void
  placeHolder?: string
  html: string
}

const TagList: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  tags,
  onSetTags,
  placeHolder,
  html,
}) => {
  const { onGanerateTagFormContent } = useGanerateTagFormContent()

  const [tagInput, setTagInput] = useState<string>('')

  const pushTextToTags = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      let tagInputProps = tagInput.replace(/[&\/\\#,+\-_()$~%.'":*?<>{}-]/g, '')
      tagInputProps = tagInputProps.replace(' ', '')
      if (tagInputProps) {
        if (tags.filter(tag => tag === tagInputProps).length === 0) {
          onSetTags([...tags, tagInputProps])
        }
      }
      setTagInput('')
    } else {
      setTagInput(e.target.value)
    }
  }

  const removeTag = (tagRemove: string) => {
    onSetTags(tags.filter(tag => tag !== tagRemove))
  }

  const onGenerateTagsToTags = () => {
    let generateTags = onGanerateTagFormContent(html)
    let tagsAdd: string[] = []
    generateTags.forEach(generateTag => {
      if (tags.filter(tag => tag === generateTag).length === 0) {
        tagsAdd.push(generateTag)
      }
    })
    onSetTags([...tags, ...tagsAdd])
  }

  return (
    <TagListWrapper>
      <div className="label-wrapper">
        <label>{label}</label>
        <Button
          onClick={() => {
            Swal.fire({
              title: 'ต้องการเพิ่มแท็กอัตโนมัติใช่ หรือไม่?',
              showCancelButton: true,
              cancelButtonText: 'ยกเลิก',
              confirmButtonText: 'ยืนยัน',
              reverseButtons: true,
            }).then(result => {
              if (result.isConfirmed) {
                onGenerateTagsToTags()
              }
            })
          }}
        >
          Generate Tag
        </Button>
      </div>

      <div className="tag-list-wrapper">
        {tags.map(tag => {
          return <Tag key={tag} removeTag={removeTag} value={tag} />
        })}
        <input
          placeholder={placeHolder ? placeHolder : 'input here...'}
          type="text"
          className="input-tag"
          value={tagInput}
          onChange={e => setTagInput(e.target.value)}
          onKeyPress={e => pushTextToTags(e)}
        />
      </div>
    </TagListWrapper>
  )
}

const TagListWrapper = styled.div`
  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .tag-list-wrapper {
    padding: 20px;
    border: 1px solid ${defaultTheme['color-system']['border-input']};
    border-radius: 6px;

    .input-tag {
      display: inline-block;
      outline: none;
    }
  }
`

export default TagList
