import React from 'react'
import styled from 'styled-components'

const DefaultImage = require('../../assets/images/common/default-image.jpeg')

interface Props {
  label?: string
  videoProps: {
    // cover: string;
    url: string
  } | null
}

const Video: React.FC<React.PropsWithChildren<Props>> = ({ label, videoProps }) => {
  return (
    <VideoWrapper>
      {label && (
        <div className="label-wrapper">
          <label>{label}</label>
        </div>
      )}
      <div className="video-wrapper">
        {videoProps?.url ? (
          <video height="100%" controls>
            <source src={videoProps.url} type="video/mp4" />
            Your browser does not support this video.
          </video>
        ) : (
          <img
            className="video-not-found"
            src={DefaultImage}
            alt="video not found"
          />
        )}
      </div>
    </VideoWrapper>
  )
}

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;

  .video-wrapper {
    height: calc(100% - 21px);
    border-radius: 6px;
    overflow: hidden;

    video {
      border-radius: 6px;
    }

    .label-wrapper {
      margin-bottom: 8px;
    }

    .video-not-found {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
export default Video
