import * as Yup from 'yup'

import { Alert, Box, Button, Input } from '@moxga/ui-components'
import { Link, useNavigate } from 'react-router-dom'

import { NewsService, ICreateNewsCategoryBody } from '@moxga/services'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import withAuth from 'hoc/withAuth'
import { useState } from 'react'

const { yupResolver } = require('@hookform/resolvers/yup')

const CreateCategoryPage = () => {
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('จำเป็นต้องกรอกชื่อ Category'),
  })
  const formOptions = { resolver: yupResolver(validationSchema) }
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ICreateNewsCategoryBody>(formOptions)

  const onSubmit = handleSubmit(async data => {
    setLoading(true)
    const result = await NewsService.createCategory(data)
    setLoading(false)

    // success case
    if (result.success) {
      navigate('/news/category')
      return
    }
    // failed case
    if (!result.success) {
      if (result.errors) {
        setError('name', { message: result.errors['name'] })
      } else {
        toast.custom(<Alert type='danger'>{result.message}</Alert>)
      }
    }
  })

  return (
    <Box>
      <div className='p-5'>
        <form onSubmit={onSubmit} className='space-y-4'>
          <Input
            placeholder='Category name'
            config={{ ...register('name') }}
            label='Category name'
            descriptionText={errors.name?.message}
            isError={!!errors.name?.message}
          />
          <div className='flex justify-between'>
            <Link to={'/news/category'}>
              <Button type='button' variant='outlined'>
                Back
              </Button>
            </Link>
            <Button type='submit' disabled={loading}>Create</Button>
          </div>
        </form>
      </div>
    </Box>
  )
}

export default withAuth(CreateCategoryPage)
