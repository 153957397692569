import { IPermissionDetail, UserPermissionEnum } from '../types'
/*
 administrator can do anything
 */
export const CheckPermission = (
  userPermission: UserPermissionEnum,
  hasAllPermissions: UserPermissionEnum[],
) => {
  if (
    (userPermission & UserPermissionEnum.ADMINISTRATOR) ===
    UserPermissionEnum.ADMINISTRATOR
  ) {
    return true
  }
  let hasPermission: boolean = true
  // verify user must have all this hasAllPermissions
  hasAllPermissions.forEach(perm => {
    // userPermission = 0 0 0 1
    // perm           = 1 0 0 0
    //                = 0 0 0 0
    if (!((userPermission & perm) === perm)) {
      hasPermission = false
    }
  })
  return hasPermission
}

export const getPermissions = () => {
  const permissionData: IPermissionDetail[] = []
  Object.values(UserPermissionEnum).forEach(permission => {
    const currentPermission =
      UserPermissionEnum[permission as keyof typeof UserPermissionEnum]

    if (Number.isInteger(currentPermission)) {
      permissionData.push({
        id: currentPermission,
        name: UserPermissionEnum[currentPermission],
        key: currentPermission,
      })
    }
  })

  return permissionData
}

export const getPermissionName = (permission: UserPermissionEnum) => {
  if (CheckPermission(permission, [UserPermissionEnum.ADMINISTRATOR])) {
    return 'ADMINISTRATOR'
  }
  return UserPermissionEnum[permission] || '-'
}
