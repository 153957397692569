import { FaSignOutAlt } from 'react-icons/fa'
import { defaultTheme } from '../../../configs/theme'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

interface Props {
  user: {
    avatar: string
    name: string
    bio: string
  }
}

const Navbar: React.FC<React.PropsWithChildren<Props>> = ({ user }) => {
  let navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <NavbarStyle>
      <div className="profile" onClick={() => setIsOpen(isOpen => !isOpen)}>
        <div className="info">
          <span className="name">{user.name}</span>
          <br />
          <span className="bio">{user.bio}</span>
        </div>
        <div>
          <img className="avatar" src={user.avatar} alt="avatar" />
        </div>
        {isOpen && (
          <div className="submenu">
            <ul className="submenu-list">
              <li
                className="submenu-item"
                onClick={async () => {
                  localStorage.removeItem('access_token')
                  localStorage.removeItem('refresh_token')
                  navigate('/')

                  return
                }}
              >
                <span className="icon">
                  <FaSignOutAlt />
                </span>{' '}
                <span>Log out</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </NavbarStyle>
  )
}

interface NavbarStyleProps {
  isOpen: boolean
}

const NavbarStyle = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 60px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 12px 20px;
  background-color: white;

  .profile {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    .info {
      text-align: right;
      margin-right: 14px;
      .bio {
        font-size: 0.9rem;
      }
    }

    .avatar {
      height: 38px;
      width: 38px;
      border: 1px solid black;
      border-radius: 999px;
    }
  }
  .submenu {
    position: absolute;
    top: calc(100% + 15px);
    right: 0;
    width: 100%;
    min-width: 150px;
    background: white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 8px 0px;

    .submenu-item {
      display: flex;
      align-items: center;
      padding: 10px 15px;

      .icon {
        margin-right: 10px;
      }

      &:hover {
        background: ${props =>
          props.theme?.color?.primary || defaultTheme.color?.primary}20;
        color: ${props =>
          props.theme?.color?.primary || defaultTheme.color?.primary};
      }
    }
  }
`

export default Navbar
