import { useEffect, useRef, useState } from 'react'

import { FaTimes } from 'react-icons/fa'
import { IModalContent } from '../ModalProvider/modalStore'
import styled from 'styled-components'
import useModal from '../../services/useModal'
import useClickOutside from '@moxga/ikizzy/lib/hooks/useClickOutside'
import Button from '../Button'

const Modal = () => {
  const { isOpen, onCloseModal, content } = useModal()

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [contentModal, setContentModal] = useState<IModalContent | null>(null)
  const ref = useRef(null)

  useClickOutside(ref, () => {})

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setContentModal(content)
        setIsOpenModal(false)
      }, 500)
    } else {
      setContentModal(content)
      setIsOpenModal(true)
    }
  }, [isOpen])

  return (
    <ModalWrapper isOpen={isOpen} isOpenModal={isOpenModal}>
      <div className="back-drop z-[50]" onClick={() => {}}></div>
      {isOpen && (
        <div className="modal-wrapper" ref={ref}>
          <div className="modal">
            {contentModal?.header && (
              <div className="modal-header-wrapper">
                <h2 className="modal-header">{contentModal?.header}</h2>
              </div>
            )}
            <div className="modal-body-wrapper">{contentModal?.body}</div>
            {contentModal?.footer && (
              <div className="modal-footer-wrapper">{contentModal?.footer}</div>
            )}
          </div>
          <button className="btn-close" onClick={onCloseModal}>
            <FaTimes />
          </button>
        </div>
      )}
    </ModalWrapper>
  )
}

interface ModalWrapperProps {
  isOpen: boolean
  isOpenModal: boolean
}

const ModalWrapper = styled.div<ModalWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  z-index: 100;
  padding: 20px;
  opacity: ${props => (props.isOpenModal ? '1' : '0')};
  visibility: ${props => (props.isOpenModal ? 'visible' : 'hidden')};

  .back-drop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: ${props => (props.isOpen ? '0.3' : '0')};
    transition: opacity 0.5s;
  }

  .modal-wrapper {
    display: inline-block;
    position: relative;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transform: translateY(${props => (props.isOpen ? '0' : '50px')});
    transition: all 0.5s;

    .btn-close {
      position: absolute;
      top: -5px;
      right: -5px;
      display: flex;
      width: 34px;
      height: 34px;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: white;
      box-shadow: 0px 3px 8px rgba(167, 174, 181, 0.4);
      &:hover {
        opacity: 95%;
      }
    }
    .modal {
      display: inline-block;
      position: relative;
      min-width: 350px;
      max-width: 100%;
      background-color: white;
      box-shadow: 0px 4px 15px rgba(44, 63, 88, 0.35);
      border-radius: 6px;
      overflow: hidden;

      .modal-header-wrapper {
        padding: 14px 26px;
        background-color: #f8f8f8;

        .modal-header {
          font-size: 1.2rem;
        }
      }
      .modal-body-wrapper {
        padding: 14px 26px;
      }
      .modal-footer-wrapper {
        padding: 14px 26px;
        border-top: 1px solid #ebe9f1;
      }
    }
  }
`

export default Modal
