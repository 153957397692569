import 'react-datepicker/dist/react-datepicker.css'

import { IDatePicker, TInputColor, TInputSize } from '../../interfaces/input'

import ReactDatePicker from 'react-datepicker'
import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'
import { useMemo } from 'react'

const DatePicker: React.FC<React.PropsWithChildren<IDatePicker>> = ({
  label,
  captionText,
  descriptionText,
  size = 'md',
  color = 'primary',
  isSuccess = false,
  isError = false,
  disabled = false,
  fullWidth = false,
  value,
  onChange,
}) => {
  let datepickerStyleProps = useMemo(() => {
    return {
      size,
      color,
      isSuccess,
      isError,
      fullWidth,
    }
  }, [size, color, isSuccess, isError, fullWidth])
  return (
    <DatePickerWrapper {...datepickerStyleProps}>
      {(label || captionText) && (
        <div className="label-wrapper">
          <label className="label">{label}</label>
          <span className="caption-text">{captionText}</span>
        </div>
      )}
      <ReactDatePicker
        className="datepicker"
        selected={new Date(value as string) || new Date()}
        onChange={(date: Date) => {
          onChange && onChange(date.toString())
        }}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
        disabled={disabled}
      />
      {descriptionText && (
        <span className="description-text">{descriptionText}</span>
      )}
    </DatePickerWrapper>
  )
}

const datePickerStyleSizes = {
  sm: {
    'font-size': '0.8rem',
    padding: '8px 15px',
  },
  md: {
    'font-size': '1rem',
    padding: '8px 15px',
  },
  lg: {
    'font-size': '1.2rem',
    padding: '14px 15px',
  },
}

interface DatePickerWrapperProps {
  size: TInputSize
  color: TInputColor | string
  isSuccess: boolean
  isError: boolean
  fullWidth: boolean
}

const DatePickerWrapper = styled.div<DatePickerWrapperProps>`
  position: relative;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};

  .label-wrapper {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .datepicker {
    width: 100%;
    border: 1px solid
      ${props =>
        props.isSuccess
          ? props.theme.color?.success || defaultTheme.color?.success
          : props.isError
          ? props.theme.color?.danger || defaultTheme.color?.danger
          : props.theme?.['color-system']?.['border-input'] ||
            defaultTheme['color-system']?.['border-input']};
    border-radius: 5px;

    ${props => datePickerStyleSizes[props.size]}

    ::-webkit-input-placeholder {
      color: ${props =>
        props.theme?.['color-system']?.['placeholder'] ||
        defaultTheme['color-system']?.['placeholder']};
    }

    :-ms-input-placeholder {
      color: ${props =>
        props.theme?.['color-system']?.['placeholder'] ||
        defaultTheme['color-system']?.['placeholder']};
    }

    ::placeholder {
      color: ${props =>
        props.theme?.['color-system']?.['placeholder'] ||
        defaultTheme['color-system']?.['placeholder']};
    }
  }

  .datepicker:focus {
    outline: 1px solid
      ${props =>
        props.theme?.color?.[props.color] ||
        defaultTheme.color?.[props.color as TInputColor] ||
        props.color};
  }

  .description-text {
    margin-top: 6px;
    color: ${props =>
      props.isSuccess
        ? props.theme.color?.success || defaultTheme.color?.success
        : props.isError
        ? props.theme.color?.danger || defaultTheme.color?.danger
        : props.theme?.['color-system']?.['placeholder'] ||
          defaultTheme['color-system']?.['placeholder']};
  }
`

export default DatePicker
