import { Alert, Box, Input, Table } from '@moxga/ui-components'
import { INewsCountry, NewsService } from '@moxga/services'

import withAuth from 'hoc/withAuth'
import AddNewCountryButton from './components/AddNewCountryButton'
import DeleteCountryButton from './components/DeleteCountryButton'
import { compose, useAsync, withHandlers, withProps } from '@moxga/utils'
import EditCountryButton from './components/EditCountryButton'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import useErrorToaster from 'hooks/useErrorToaster'

interface Props {
  countries: INewsCountry[]
  isLoading: boolean
  handleReloadData: () => void
}

const NewsCountryPage: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  countries,
  handleReloadData,
}) => {
  return (
    <Box>
      <div className="p-[20px] flex space-x-4 justify-end items-center">
        <span className="flex items-center">
          <span className="mr-4">Search: </span>
          <div>
            <Input placeholder="ค้นหา" />
          </div>
        </span>
        <AddNewCountryButton />
      </div>
      <Table
        headers={[
          {
            label: 'No.',
            key: 'id',
            render: (_, index) => <span>{index + 1}</span>,
          },
          {
            label: 'Country name',
            key: 'label',
            thStyle: {
              textAlign: 'center',
            },
            tdStyle: {
              textAlign: 'center',
            },
          },
          {
            label: 'Country code',
            key: 'code',
            thStyle: {
              textAlign: 'center',
            },
            tdStyle: {
              textAlign: 'center',
            },
          },
          {
            label: 'Detail',
            key: 'id',
            thStyle: {
              textAlign: 'right',
            },
            render: (value, index, item) => {
              return (
                <div className="flex justify-end">
                  <EditCountryButton countryId={value} />
                  <DeleteCountryButton
                    countryId={value}
                    countryName={item.label}
                    handleReloadData={handleReloadData}
                  />
                </div>
              )
            },
          },
        ]}
        data={countries}
        isLoading={isLoading}
      />
    </Box>
  )
}
export default compose(
  withAuth,
  withProps(() => {
    const {
      data: result,
      execute,
      isLoading,
      error,
    } = useAsync({
      asyncFn: () => NewsService.listCountryCode(),
      initialData: [],
      isImmediate: true,
    })

    useErrorToaster(error)

    return {
      countries: result,
      isLoading,
      execute,
    }
  }),
  withHandlers({
    handleReloadData:
      ({ execute }: { execute: () => {} }) =>
      () => {
        // reload country data
        execute()
      },
  })
)(NewsCountryPage)
