import { createElement } from 'react'
import hoistNonReactStatics from './hoist-non-react-statics'

import mapValues from './mapValues'

import setDisplayName from './setDisplayName'
import wrapDisplayName from './wrapDisplayName'

const withHandlers = handlers => BaseComponent => {
  const WithHandlers = props => {
    const mappedHandlers = mapValues(
      typeof handlers === 'function' ? handlers(props) : handlers,

      createHandler =>
        (...args) => {
          const handler = createHandler(props)

          if (
            process.env.NODE_ENV !== 'production' &&
            typeof handler !== 'function'
          ) {
            console.error(`
            withHandlers(): Expected a map of higher-order functions.
            Refer to "recompose" docs for more info.
          `)
          }

          return handler(...args)
        }
    )

    return createElement(BaseComponent, {
      ...props,
      ...mappedHandlers,
    })
  }

  hoistNonReactStatics(WithHandlers, BaseComponent)

  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(BaseComponent, 'withHandlers'))(
      WithHandlers
    )
  }

  return WithHandlers
}

export default withHandlers
