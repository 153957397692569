import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../configs/theme'

import { Editor } from '@tinymce/tinymce-react'
import { useState } from 'react'

type Props = {
  label?: string
  value: string
  onChange: (value: string) => void
  isError?: boolean
  descriptionText?: string
  imageHandler?: (image: Blob) => Promise<string>
}

const Editors: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  value,
  onChange,
  isError = false,
  descriptionText,
  imageHandler = () => '',
}) => {
  const [html, setHTML] = useState(value)

  const plugins = [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'code',
    'help',
    'wordcount',
    'emoticons',
  ]
  const toolbar =
    'undo redo | blocks | ' +
    'bold italic forecolor backcolor emoticons | alignleft aligncenter ' +
    'alignright alignjustify | lineheight bullist numlist outdent indent | ' +
    'removeformat | help | image | preview'

  const LINK_WHITE_LIST = [
    'ikizzy.com',
    'gamer.run',
    'staging.ikizzy.com',
    'staging.gamer.run',
  ]
  const urlConvertor = (url: string, node: Node | string | undefined) => {
    if (
      node == 'a' &&
      !url.match(/\/go\/redirect\?link\=/i) &&
      !LINK_WHITE_LIST.some(function (i) {
        return new RegExp('\\b' + i + '\\b').test(url)
      })
    ) {
      return '/go/redirect?link=' + url
    } else {
      return url
    }
  }

  const getLogoUrl = (link: string) => {
    let url = ''
    switch (true) {
      case /youtube/.test(link):
        url =
          'https://cdn.discordapp.com/attachments/1013826489101336729/1028330799222755479/YouTube_full-color_icon_2017.svg.webp'
        break
      case /facebook/.test(link):
        url =
          'https://cdn.discordapp.com/attachments/1013826489101336729/1027944575546953758/facebook.png'
        break
      case /twitter/.test(link):
        url =
          'https://cdn.discordapp.com/attachments/1013826489101336729/1028330877639462972/twitter-logo-png-twitter-logo-vector-png-clipart-library-518.png'
        break
      case /ikizzy/.test(link):
        url =
          'https://cdn.discordapp.com/attachments/1013826489101336729/1028598119090364466/favicon.png'
        break
      default:
        url =
          'https://cdn.discordapp.com/attachments/1013826489101336729/1028331439500042310/png-transparent-computer-icons-hyperlink-symbol-link-miscellaneous-text-logo-removebg-preview.png'
    }
    return `<img class="inline mb-1" src="${url}" width="15" height="15" />`
  }

  const addCustomLogo = (str: string) => {
    setHTML(str)
    const arr = str.split(/\r?\n/)
    for (let i = 0; i < arr.length; i++) {
      arr[i] = addLogo(arr[i])
    }
    onChange(arr.join('\r\n'))
  }

  const addLogo = (str: string) => {
    let doc = new DOMParser().parseFromString(str, 'text/html')
    let a_tags = doc.getElementsByTagName('a')
    if (a_tags.length > 0) {
      let newStr = []
      for (let i = 0; i < a_tags.length; i++) {
        const innerHTML = a_tags[i].innerHTML
        const aHtml = a_tags[i].outerHTML
        const href = a_tags[i].href
        if (a_tags[i].getElementsByTagName('img').length == 0) {
          a_tags[i].innerHTML = `${getLogoUrl(href)} ${innerHTML}`
          a_tags[i].classList.add('pb-5')
        }
        const pElement = doc.getElementsByTagName('p')[0]
        let pHtml = pElement.outerHTML //<p>${a_tags[i].outerHTML}</p>
        newStr.push(pHtml.replace(aHtml, a_tags[i].outerHTML))
      }
      return newStr.join(' ')
    } else {
      return str
    }
  }

  return (
    <EditorWrapper isError={isError}>
      {label ? (
        <div className="label-wrapper">
          <label>{label}</label>
        </div>
      ) : null}

      <Editor
        apiKey="3i4gphq5ujqektq92k6iaoj7gopwt9yedp99u1tt1582hkvn"
        value={html}
        init={{
          height: 500,
          menubar: true,
          plugins: plugins,
          toolbar: toolbar,
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          images_upload_handler: async blobInfo => {
            return imageHandler(blobInfo.blob())
          },
          urlconverter_callback: (url, node, on_save, name) => {
            return urlConvertor(url, node)
          },
        }}
        onEditorChange={(newValue, editor) => {
          addCustomLogo(newValue)
        }}
      />
      {descriptionText && (
        <span className="description-text">{descriptionText}</span>
      )}
    </EditorWrapper>
  )
}

interface EditorWrapperProps {
  isError: boolean
}

const EditorWrapper = styled.div<EditorWrapperProps>`
  .label-wrapper {
    margin-bottom: 8px;
  }

  .description-text {
    margin-top: 6px;
    color: ${props =>
      props.isError
        ? props.theme.color?.danger || defaultTheme.color?.danger
        : props.theme?.['color-system']?.['placeholder'] ||
          defaultTheme['color-system']?.['placeholder']};
  }
`

export default Editors
