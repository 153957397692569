function mapValues(obj, func, wrapType = {}) {
  const result = wrapType
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (typeof obj[key] !== 'undefined') {
      result[key] = func(obj[key], key)
    }
  }
  return result
}

export default mapValues
