import withAuth from 'hoc/withAuth'
import { Box } from '@moxga/ui-components'

const DashboardPage = () => {
  // TODO: view stats
  return (
    <Box>
      <div className="p-4 h-[300px]"></div>
    </Box>
  )
}

export default withAuth(DashboardPage)
