import Avatar from '../Avatar'
import Box from '../Box'
import { FiActivity } from 'react-icons/fi'
import { ReactNode } from 'react'
import { TThemeColor } from '../../interfaces/theme'
import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'

interface Props {
  title?: string
  data: {
    icon?: ReactNode
    color?: TThemeColor
    value: number
    label: string
  }[]
}

const Statistics: React.FC<React.PropsWithChildren<Props>> = ({ title = 'Statistics', data }) => {
  let onFormatValue = (value: number) => {
    if (value < 1000) {
      return value
    } else if (value < 1000000) {
      // Anything less than a million
      return Math.floor(value / 1000)
        .toFixed(1)
        .replace(/\.0$/, '') + 'K';
    } else if (value < 1000000000) {
      // Anything less than a billion
      return Math.floor(value / 1000000)
        .toFixed(1)
        .replace(/\.0$/, '') + 'M';
    } else {
      // At least a billion
      return Math.floor(value / 1000000000)
        .toFixed(1)
        .replace(/\.0$/, '') + 'B';
    }
  }

  return (
    <Box>
      <StatisticsWrapper>
        <h2 className="title">{title}</h2>
        <div className="stat-wrapper">
          {data.map((d, index) => {
            return (
              <div className="stat-box">
                <Avatar
                  size="lg"
                  icon={d.icon || <FiActivity />}
                  color={d.color || 'primary'}
                />
                <div className="data">
                  <p className="value">{onFormatValue(d.value)}</p>
                  <p>{d.label}</p>
                </div>
              </div>
            )
          })}
        </div>
      </StatisticsWrapper>
    </Box>
  )
}

const StatisticsWrapper = styled.div`
  padding: 20px;

  .title {
    font-weight: bold;
    margin-bottom: 24px;
  }

  .stat-wrapper {
    display: grid;
    grid-template-columns: auto;
    gap: 8px;

    .stat-box {
      display: flex;
      align-items: center;
      width: 100%;

      .data {
        margin-left: 18px;

        .value {
          font-weight: bold;
        }
      }
    }

    @media screen and (min-width: ${defaultTheme.screen?.sm}px) {
      grid-template-columns: auto auto;
    }

    @media screen and (min-width: ${defaultTheme.screen?.lg}px) {
      grid-template-columns: auto auto auto auto;
    }
  }
`

export default Statistics
