import { ModalStoreContext } from '../components/ModalProvider/modalStore'
import { useContext } from 'react'

const useModal = () => {
  const modalStore = useContext(ModalStoreContext)

  return modalStore
}

export default useModal
