import { useEffect, useState } from 'react'

import Alert from '../Alert'
import Button from '../Button'
import CopyToClipboard from 'react-copy-to-clipboard'
import Input from '../Input'
import styled from 'styled-components'
import toast from 'react-hot-toast'

interface Props {
  value: string
  label?: string
}

const CopyURL: React.FC<React.PropsWithChildren<Props>> = ({ value, label }) => {
  const [copy, setCopy] = useState<boolean>(false)
  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false)
      }, 3000)
    }
  }, [copy])

  const onCopy = () => {
    setCopy(true)
    toast.custom(<Alert type="success">Copied to clipboard</Alert>)
  }

  return (
    <CopyURLWrapper>
      {label && (
        <div className="label-wrapper">
          <label>{label}</label>
        </div>
      )}
      <div className="input-copy-wrapper">
        <Input config={{ defaultValue: value }} fullWidth disabled />
        <CopyToClipboard text={value} onCopy={onCopy}>
          <Button
            style={{
              marginLeft: '16px',
            }}
          >
            {copy ? 'Copied' : 'Copy'}
          </Button>
        </CopyToClipboard>
      </div>
    </CopyURLWrapper>
  )
}

const CopyURLWrapper = styled.div`
  width: 100%;

  .label-wrapper {
    margin-bottom: 8px;
  }

  .input-copy-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
`

export default CopyURL
