import { ITheme } from '../interfaces/theme'

export const defaultTheme: ITheme = {
  color: {
    primary: '#7367F0',
    secondary: '#82868B',
    success: '#28C76F',
    danger: '#EA5455',
    warning: '#FF9F43',
    info: '#00CFE8',
    dark: '#4B4B4B',
    light: '#BABFC7',
  },
  'color-system': {
    'border-input': '#D8D6DE',
    placeholder: '#D8D6DE',
    'border-table': '#F3F4F6',
  },
  screen: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
  },
}
