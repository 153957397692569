import { FiX } from 'react-icons/fi'
import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'

interface Props {
  value: string
  removeTag: (tagRemove: string) => void
}
const Tag: React.FC<React.PropsWithChildren<Props>> = ({ value, removeTag }) => {
  return (
    <TagWrapper>
      <div className="tag-item">
        <span className="label">#{value}</span>
        <button onClick={() => removeTag(value)}>
          <FiX />
        </button>
      </div>
    </TagWrapper>
  )
}

const TagWrapper = styled.div`
  display: inline-block;

  .tag-item {
    display: flex;
    align-items: center;
    background-color: ${props =>
      props.theme.color.primary || defaultTheme.color?.primary}20;
    color: ${props => props.theme.color.primary || defaultTheme.color?.primary};
    padding: 4px 12px;
    border-radius: 999px;
    margin-right: 4px;

    .label {
      margin-right: 4px;
    }
  }
`

export default Tag
