import * as Yup from 'yup'

import { Alert, Box, Button, Input } from '@moxga/ui-components'
import { Link, useNavigate } from 'react-router-dom'

import { ICreateGroupJournalistBody } from '@moxga/services/src/types/request'
import { NewsService } from '@moxga/services'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import withAuth from 'hoc/withAuth'

const { yupResolver } = require('@hookform/resolvers/yup')

const CreateGroupJournalistPage = () => {
  let navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('จำเป็นต้องกรอกชื่อกลุ่ม'),
  })
  const formOptions = { resolver: yupResolver(validationSchema) }
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ICreateGroupJournalistBody>(formOptions)

  const onSubmit = handleSubmit(async data => {
    const result = await NewsService.journalist.createGroup(data)
    // success case
    if (result.success) {
      navigate('/journalist')
      return
    }
    // failed case
    if (!result.success) {
      if (result.errors) {
        setError('name', { message: result.errors['name'] })
      } else {
        // TODO: show toast
        toast.custom(<Alert type="danger">{result.message}</Alert>)
      }
    }
  })

  return (
    <Box>
      <div className="p-5">
        <form onSubmit={onSubmit} className="space-y-4">
          <Input
            placeholder="Group name"
            config={{ ...register('name') }}
            label="Group name"
            descriptionText={errors.name?.message}
            isError={!!errors.name?.message}
          />
          <div className="flex justify-between">
            <Link to={'/journalist'}>
              <Button type="button" variant="outlined">
                Back
              </Button>
            </Link>
            <Button type="submit">Create</Button>
          </div>
        </form>
      </div>
    </Box>
  )
}

export default withAuth(CreateGroupJournalistPage)
