import { CheckPermission, UserPermissionEnum } from '@moxga/services'
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  withProps,
} from '@moxga/utils'
import Unauthorized from 'components/Unauthorized'
import { useAppStoreContext } from 'store/AppStore/AppStore'

/**
 * HOC to verify user's permissions
 *
 * @param {boolean} noPermissionsRenderNothing set to `true` to render nothing, if user doesn't have required permission.
 * @param {UserPermissionEnum[]} requiredPermissions array | number of required permissions
 * @returns PageViewComponent
 */
const withPermission =
  ({
    noPermissionsRenderNothing = false,
    requiredPermissions,
  }: {
    noPermissionsRenderNothing?: boolean
    requiredPermissions: UserPermissionEnum[]
  }) =>
  (PageView: any) => {
    const authorizedPageView = compose(
      withProps(() => {
        const { user } = useAppStoreContext()

        return {
          hasPermission: CheckPermission(
            user?.permission!,
            requiredPermissions
          ),
        }
      }),
      branch(
        ({ hasPermission }: { hasPermission: boolean }) =>
          !hasPermission && noPermissionsRenderNothing,
        renderNothing
      ),
      branch(
        ({ hasPermission }: { hasPermission: boolean }) => !hasPermission,
        renderComponent(Unauthorized)
      )
    )(PageView)

    return authorizedPageView
  }

export default withPermission
