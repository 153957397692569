import { createContext, useState, useContext } from 'react'

import { IUser } from '@moxga/services'

export interface IAppStore {
  user: IUser | null
  onSetUser: (user: IUser) => void
  onClearUser: () => void
}

export const DefaultAppStore: IAppStore = {
  user: null,
  onSetUser: () => {},
  onClearUser: () => {},
}

export const AppStoreContext = createContext<IAppStore>(DefaultAppStore)

const AppStore = () => {
  const [user, setUser] = useState<IUser | null>(null)

  const onSetUser = (user: IUser) => {
    setUser(user)
  }
  const onClearUser = () => {
    setUser(null)
  }

  return {
    user,
    onSetUser,
    onClearUser,
  }
}

export const useAppStoreContext = () => useContext(AppStoreContext)

export default AppStore
