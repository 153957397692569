import { fetcher } from '../libs/fetcher'
import { IProfile, IUser, PaginationQuery, UserPermissionEnum } from '../types'
import { IPaginationResponse, Response } from '../types/response'
import { IChangePasswordBody, IEditProfileBody } from '../types/request'
import { CancelToken } from 'axios'

export const UserService = {
  uploadAvatar(file: File) {
    return fetcher.postForm<{ imageUrl: string }>(`/api/users/avatar`, {
      image: file,
    })
  },
  deleteAvatar() {
    return fetcher.delete<{}>(`/api/users/avatar`)
  },

  editBio(bio: string) {
    return fetcher.put(`/api/users/bio`, {
      bio,
    })
  },
  editProfile(d: IEditProfileBody) {
    return fetcher.put<{}>(`/api/users/profile`, d)
  },
  changePassword(data: IChangePasswordBody) {
    return fetcher.put(`/api/users/change-password`, data)
  },
  uploadCover(file: File) {
    return fetcher.postForm<{ imageUrl: string }>(`/api/users/cover`, {
      image: file,
    })
  },
  deleteCover() {
    return fetcher.delete(`/api/users/cover`)
  },

  // a public profile search
  profile(username: string) {
    return fetcher.get<IProfile>(`/api/users/profile/${username}`)
  },
  setNewPassword(password: string, confirmPassword: string) {
    return fetcher.put(`/api/users/password`, {
      password,
      confirmPassword,
    })
  },
  setNewUsername(username: string) {
    return fetcher.put(`/api/users/username`, {
      username,
    })
  },
}

export const UserBackofficeService = {
  search({
    search,
    cancelToken,
    page,
    limit,
  }: {
    search?: string
    cancelToken?: CancelToken
  } & PaginationQuery) {
    return fetcher.get<IPaginationResponse<IUser>>(`/api/users/search`, {
      params: {
        search,
        page,
        limit,
      },
      cancelToken,
    })
  },
  detail(userId: string) {
    return fetcher.get<IUser>(`/api/users/detail/${userId}`)
  },

  addPermission(userId: string, permission: UserPermissionEnum) {
    return fetcher.put(`/api/users/permissions/${userId}/add`, {
      permission,
    })
  },

  removePermission(userId: string, permission: UserPermissionEnum) {
    return fetcher.put(`/api/users/permissions/${userId}/remove`, {
      permission,
    })
  },
}
