import { ReactNode, createContext, useState } from 'react'

export interface IModalStore {
  isOpen: boolean
  onOpenModal: (content: IModalContent) => void
  // deprecated
  onCloseModal: () => void
  content: IModalContent | null
  close: () => void
}

export const DefaultAppStore: IModalStore = {
  isOpen: false,
  onOpenModal: (content: IModalContent) => {},
  onCloseModal: () => {},
  content: null,
  close: () => {},
}

export const ModalStoreContext = createContext<IModalStore>(DefaultAppStore)

export interface IModalContent {
  header?: ReactNode
  body?: ReactNode
  footer?: ReactNode
}

const ModalStore = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [content, setContent] = useState<IModalContent | null>(null)

  const onOpenModal = (content: IModalContent) => {
    setContent(content)
    setIsOpen(true)
  }

  const onCloseModal = () => {
    setContent(null)
    setIsOpen(false)
  }

  return {
    isOpen,
    onOpenModal,
    onCloseModal,
    content,
    close: onCloseModal,
  }
}

export default ModalStore
