import { createElement } from 'react'
import hoistNonReactStatics from './hoist-non-react-statics'

import setDisplayName from './setDisplayName'
import wrapDisplayName from './wrapDisplayName'

const renderComponent = Component => () => {
  const RenderComponent = props => createElement(Component, props)

  hoistNonReactStatics(RenderComponent, Component)

  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(Component, 'renderComponent'))(
      RenderComponent
    )
  }

  return RenderComponent
}

export default renderComponent
