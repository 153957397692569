import { createElement } from 'react'
import hoistNonReactStatics from './hoist-non-react-statics'

import setDisplayName from './setDisplayName'
import wrapDisplayName from './wrapDisplayName'

const mapProps = propsMapper => BaseComponent => {
  const MapProps = props => createElement(BaseComponent, propsMapper(props))

  hoistNonReactStatics(MapProps, BaseComponent)

  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(BaseComponent, 'mapProps'))(MapProps)
  }

  return MapProps
}

export default mapProps
