import styled from 'styled-components'
import { rem } from 'polished'

interface Props {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info'
  size?: 'sm' | 'md'
}

const Badge: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  variant,
  size,
  children,
}) => {
  return <BadgeStyled className={`${variant} ${size}`}>{children}</BadgeStyled>
}

Badge.defaultProps = {
  variant: 'primary',
}

const BadgeStyled = styled.div`
  padding: ${rem(4)} ${rem(12)};
  border-radius: ${rem(5)};
  color: white;
  display: inline-block;

  &.sm {
    padding: ${rem(2)} ${rem(8)};
    font-size: ${rem(12)};
  }

  &.primary {
    background-color: ${({ theme }) => theme.color.primary};
  }

  &.secondary {
    background-color: ${({ theme }) => theme.color.secondary};
  }

  &.success {
    background-color: ${({ theme }) => theme.color.success};
  }

  &.danger {
    background-color: ${({ theme }) => theme.color.danger};
  }

  &.warning {
    background-color: ${({ theme }) => theme.color.warning};
  }

  &.info {
    background-color: ${({ theme }) => theme.color.info};
  }
`

export default Badge
