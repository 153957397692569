import { Box, Statistics } from '@moxga/ui-components'
import { FiBookOpen, FiEye, FiTablet } from 'react-icons/fi'
import {
  IJournalistGroupItem,
  IJournalistGroupStatistics,
  INewsCategory,
  INewsListItem,
  IPaginationResponse,
  NewsJournalistClassEnum,
  NewsService,
} from '@moxga/services'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import MembersSection from 'components/Journalist/Group/Detail/MembersSection'
import NewsSection from 'components/Journalist/Group/Detail/NewsSection'
import withAuth from '../../../hoc/withAuth'
import CategorySection from './components/CategorySection'

function RenderError({ message }: { message: string }) {
  return (
    <div>
      <p>error {message}</p>
    </div>
  )
}

function Skeleton() {
  return (
    <Box>
      <div className={'bg-gray-100 animate-pulse w-full h-[100px]'}></div>
      <div className={'bg-gray-100 mt-10 animate-pulse w-full h-[500px]'}></div>
    </Box>
  )
}

const JournalistDetailPage = () => {
  const { groupId } = useParams<{ groupId: string }>()

  const [news, setNews] = useState<IPaginationResponse<INewsListItem>>()
  const [detail, setDetail] = useState<IJournalistGroupItem>()
  const [loading, setLoading] = useState(true)
  const [isError, setError] = useState<string | null>(null)
  const [categories, setCategories] = useState<INewsCategory[]>([])
  const [stats, SetStatistics] = useState<IJournalistGroupStatistics>({
    totalCategory: 0,
    totalMembers: 0,
    totalNews: 0,
    totalNewsInThisWeek: 0,
    totalViews: 0,
  })

  useEffect(() => {
    if (!groupId) {
      return
    }
    Promise.all([
      NewsService.journalist.statistics(groupId).then(r => {
        if (r.success) {
          SetStatistics(r.data)
        }
      }),
      NewsService.journalist.detailGroup(groupId).then(r => {
        if (!r.success) {
          setError(r.message)
          return
        }
        setDetail(r.data)
      }),
      NewsService.journalist
        .listNews(groupId!, {
          page: 1,
          limit: 20,
          search: '',
        })
        .then(r => {
          if (!r.success) {
            setError(r.message)
            return
          }
          setNews(r.data)
        }),
    ]).finally(() => {
      setLoading(false)
    })
  }, [])

  if (isError) {
    return <RenderError message={isError} />
  }
  if (loading) {
    return <Skeleton />
  }

  return (
    <div className="space-y-6">
      <Statistics
        title="Statistics"
        data={[
          {
            icon: <FiTablet />,
            value: stats.totalCategory,
            label: 'Category',
          },
          {
            icon: <FiBookOpen />,
            value: stats.totalNews,
            label: 'News',
          },
          {
            icon: <FiBookOpen />,
            value: stats.totalNewsInThisWeek,
            label: 'News (this week)',
          },
          {
            icon: <FiEye />,
            value: stats.totalViews,
            label: 'Total views',
          },
        ]}
      />
      {detail?.me?.level === NewsJournalistClassEnum.Head && (
        <CategorySection groupId={groupId!} />
      )}
      <MembersSection members={detail?.members || []} groupId={groupId!} />
    </div>
  )
}
export default withAuth(JournalistDetailPage)
