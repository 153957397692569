import { ReactNode, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { defaultTheme } from '../../../../../configs/theme'
import styled from 'styled-components'

interface Props {
  icon: ReactNode
  label: string
  href: string
}

const MenuWithoutSubmenu: React.FC<React.PropsWithChildren<Props>> = ({ icon, label, href }) => {
  const { pathname } = useLocation()

  const isMatch = useMemo(() => {
    return !!matchPath(href + '/*', pathname)
  }, [href, pathname])

  return (
    <Link to={href}>
      <MenuWithoutSubmenuStyle isMatch={isMatch}>
        <span className="icon">{icon}</span>
        <span className="label">{label}</span>
      </MenuWithoutSubmenuStyle>
    </Link>
  )
}

interface MenuWithoutSubmenuStyleProps {
  isMatch: boolean
}

const MenuWithoutSubmenuStyle = styled.li<MenuWithoutSubmenuStyleProps>`
  display: flex;
  align-items: center;
  padding: 12px 18px;
  cursor: pointer;
  border-radius: 5px;
  ${props =>
    props.isMatch && {
      background: `linear-gradient(45deg, ${
        props.theme?.color?.primary || defaultTheme.color?.primary
      } 0%, ${
        props.theme?.color?.primary || defaultTheme.color?.primary
      }90 100%)`,
      color: 'white',
      'box-shadow': `0px 0px 10px ${
        props.theme?.color?.primary || defaultTheme.color?.primary
      }65`,
    }}

  .icon {
    margin-right: 15px;
    transition: all;
    transition-duration: 0.3s;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &:hover {
    .icon {
      margin-left: 5px;
      margin-right: ${props => (props.isMatch ? '15px' : '10px')};
    }
  }
`

export default MenuWithoutSubmenu
