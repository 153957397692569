import React, { useRef, useState } from 'react'

import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'

const DefaultImage = require('../../assets/images/common/default-image.jpeg')

interface Props {
  onChange: (x: File) => any
  label?: string
  isSquare?: boolean
  ratio?: {
    width: number
    height: number
  }
  defaultImage?: string | File
}

const ImageUpload: React.FC<React.PropsWithChildren<Props>> = ({
  onChange,
  label,
  ratio = {
    width: 16,
    height: 9,
  },
  defaultImage,
}) => {
  const input = useRef<HTMLInputElement>(null)
  const [image, setImage] = useState<File>()

  return (
    <ImageUploadWrapper>
      {label && (
        <div className="label-wrapper">
          <label>{label}</label>
        </div>
      )}
      <div
        className="input-wrapper"
        style={{
          paddingTop: `${(100 * ratio.height) / ratio.width}%`,
        }}
        onClick={() => input.current?.click()}
      >
        <img
          className="img"
          src={
            image
              ? URL.createObjectURL(image)
              : defaultImage
              ? typeof defaultImage === 'string'
                ? defaultImage
                : URL.createObjectURL(defaultImage)
              : DefaultImage
          }
          alt="main-cover"
        />
        <input
          ref={input}
          type="file"
          multiple={false}
          hidden={true}
          onChange={e => {
            if (e.target.files?.length) {
              setImage(e.target.files[e.target.files.length - 1])
              onChange(e.target.files[e.target.files.length - 1])
            }
          }}
        />
        <button type="button" className="btn-upload ">
          click for upload image
        </button>
      </div>
    </ImageUploadWrapper>
  )
}

const ImageUploadWrapper = styled.div`
  .label-wrapper {
    margin-bottom: 8px;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
    overflow: hidden;

    .img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .btn-upload {
      position: absolute;
      width: 100%;
      bottom: 0;
      background: ${props =>
        props.theme.color.primary || defaultTheme.color?.primary}99;
      text-align: center;
      padding: 16px;
      text-transform: uppercase;
      color: white;
    }

    &:hover {
      .btn-upload {
        background: ${props =>
          props.theme.color.primary || defaultTheme.color?.primary};
      }
    }
  }
`

export default ImageUpload
