import { useEffect, useState } from 'react'

import { AppStoreContext } from 'store/AppStore/AppStore'
import { AuthService } from '@moxga/services'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

const withAuth = (WrappedComponent: any) => (props: any) => {
  let navigate = useNavigate()
  const { user, onSetUser } = useContext(AppStoreContext)
  const [isReady, setIsReady] = useState<boolean>(false)
  const accessToken = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')

  useEffect(() => {
    GetUser()
  }, [])

  const GetUser = async () => {
    if (accessToken && refreshToken) {
      if (!user) {
        const result = await AuthService.me()
        if (result.success) {
          onSetUser(result.data)
          setIsReady(true)
        } else {
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          navigate('/sign-in')
        }
      } else {
        setIsReady(true)
      }
    } else {
      navigate('/sign-in')
    }
  }

  if (!isReady) {
    return <div></div>
  }
  return <WrappedComponent {...props} />
}

export default withAuth
