import { UserPermissionEnum } from '@moxga/services'
import { Button } from '@moxga/ui-components'
import withPermission from 'hoc/withPermission'
import { Link } from 'react-router-dom'

interface Props {
  countryId: number
}

const EditCountryButton: React.FC<React.PropsWithChildren<Props>> = ({ countryId }) => {
  return (
    <Link to={`/news/country/${countryId}`} className="mr-2">
      <Button size="sm">Edit</Button>
    </Link>
  )
}

export default withPermission({
  noPermissionsRenderNothing: true,
  requiredPermissions: [UserPermissionEnum.NEWS_COUNTRY_EDIT],
})(EditCountryButton)
