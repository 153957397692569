import * as Yup from 'yup'

import {
  Alert,
  Box,
  Button,
  DatePicker,
  Editor,
  ImageUpload,
  Input,
  Select,
  TagList,
  Tiktok,
  Reference,
} from '@moxga/ui-components'
import { ErrorCodeEnum, NewsService } from '@moxga/services'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'

import {
  INewsDetailCreateForm,
  INewsReferences,
} from '@moxga/services/src/types'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import withAuth from 'hoc/withAuth'
import useNewsForm from '../../../../hooks/useNewsForm'
import { BackspaceIcon } from '@heroicons/react/outline'

const { yupResolver } = require('@hookform/resolvers/yup')

const CreateNewsPage = () => {
  const navigate = useNavigate()
  let { groupId } = useParams()
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('จำเป็นต้องกรอก Title'),
    // metaTitle: Yup.string().required('จำเป็นต้องกรอก Meta Title'),
    markdown: Yup.string().required('จำเป็นต้องกรอก Markdown'),
    // metaDescription: Yup.string().required('จำเป็นต้องกรอก Meta Description'),
    categoryId: Yup.string().required('จำเป็นต้องเลือก Category'),
    countryCode: Yup.string().required('จำเป็นต้องเลือก Country'),
    html: Yup.string().required('จำเป็นต้องกรอก Body'),
  })
  const formOptions = { resolver: yupResolver(validationSchema) }
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<INewsDetailCreateForm>(formOptions)
  const [{ countries, loading, categories }] = useNewsForm(groupId!)
  const [tags, setTags] = useState<string[]>([])
  const [newsReferences, setNewsReferences] = useState<INewsReferences[]>([])
  //setReference(data => [...data, { link: 'https', label: 'dwadwa' }])
  useEffect(() => {
    setValue('publishedDate', new Date().toString())
  }, [])

  const onSubmit = handleSubmit(async dataProps => {
    let data = new FormData()
    if (typeof dataProps.coverImageUrl === 'object') {
      data.append('cover', dataProps.coverImageUrl)
    } else {
      toast.custom(<Alert type="danger">กรุณาใส่รูป Cover</Alert>)
      return
    }
    if (typeof dataProps.squareImageUrl === 'object') {
      data.append('square', dataProps.squareImageUrl)
    } else {
      toast.custom(<Alert type="danger">กรุณาใส่รูป Square</Alert>)
      return
    }
    data.append('title', dataProps.title)
    if (dataProps.metaTitle) {
      data.append('metaTitle', dataProps.metaTitle)
    }
    data.append('markdown', dataProps.markdown)
    if (dataProps.metaDescription) {
      data.append('metaDescription', dataProps.metaDescription)
    }
    data.append('categoryId', dataProps.categoryId)
    data.append('countryCode', dataProps.countryCode + '')
    data.append(
      'publishedDate',
      dayjs(dataProps.publishedDate).format('YYYY-MM-DDTHH:mm:ssZ')
    )
    data.append('html', dataProps.html)
    if (tags.length > 0) {
      data.append('tags', (tags as string[]).join(','))
    } else {
      // toast.custom(<Alert type="danger">กรุณาใส่แท็ก</Alert>)
      // return
    }

    data.append('references', JSON.stringify(newsReferences))

    if (dataProps.videoUrl) {
      data.append('videoUrl', dataProps.videoUrl as string)
    }
    if (dataProps.tiktokUrl) {
      data.append('tiktokUrl', dataProps.tiktokUrl as string)
    }
    data.append('group_id', groupId as string)
    const result = await NewsService.journalist.createNews(
      groupId as string,
      data
    )
    // success case
    if (result.success) {
      toast.custom(<Alert type="success">สร้างข่าวสำเร็จ</Alert>)
      navigate(`/journalist/group/${groupId}/news`)
      return
    }
    // failed case
    if (!result.success) {
      if (result.errors) {
        if (result.code === ErrorCodeEnum.InvalidInput) {
          // show any message of errors
          Object.keys(result.errors).map(key => {
            toast.custom(
              <Alert type="danger">
                {key} {result.errors[key]}
              </Alert>
            )
            return key
          })
          setError('title', { message: result.errors['title'] })
          setError('tags', { message: result.errors['tags'] })
        }
      } else {
        // TODO: show toast
        toast.custom(<Alert type="danger">{result.message}</Alert>)
      }
    }
  })

  const imageHandler = async (file: Blob) => {
    if (file && /^image\//.test(file.type)) {
      // TODO: validate file size.
      const formData = new FormData()
      formData.append('image', file)

      const res = await NewsService.journalist.uploadImage(
        groupId as string,
        formData
      )

      if (res.success) {
        const url = res?.data?.url as string

        toast.success('Uploaded image successfully')
        return url
      } else {
        toast.error(`Upload image error. ${res.message}`)
        return ''
      }
    } else {
      toast.error('You could only upload images.')
      return ''
    }
  }
  const handleGenerateReference = (references: INewsReferences[]) => {
    setNewsReferences(references)
  }
  return (
    <Box>
      <div className="p-5">
        <div className={'mb-5'}>
          <Link to={`/journalist/group/${groupId}/news`}>
            <Button variant="outlined" size="sm">
              <BackspaceIcon className="ico h-4 w-4 mr-1" /> Back
            </Button>
          </Link>
        </div>
        {loading ? (
          <div className="text-center p-[20px]">Loading...</div>
        ) : (
          <form onSubmit={onSubmit}>
            <div className="space-y-6">
              <div className="block space-y-6 md:space-y-0 md:flex space-x-6">
                <div className="w-full md:w-[29.7%]">
                  <ImageUpload
                    onChange={(file: File) => setValue('squareImageUrl', file)}
                    label={'1:1 Cover Image'}
                    ratio={{ width: 1, height: 1 }}
                  />
                </div>
                <div className="w-full md:w-[52.8%]">
                  <ImageUpload
                    onChange={(file: File) => setValue('coverImageUrl', file)}
                    label={'16:9 Cover Image'}
                  />
                </div>
              </div>
              <div className="block space-y-6 md:space-y-0 md:grid grid-cols-2 gap-6 ">
                <Tiktok
                  label="TIKTOK URL"
                  url={watch('tiktokUrl') || ''}
                  onChangeTiktokURL={url => setValue('tiktokUrl', url)}
                />
                <Input
                  label="Video URL"
                  config={{
                    ...register('videoUrl'),
                  }}
                  fullWidth
                  placeholder="https://cf.ikizzy.com/assets/media/demo.mp4"
                />
              </div>
              <Input
                label="Title"
                config={{
                  ...register('title'),
                }}
                descriptionText={errors.title?.message}
                isError={!!errors.title?.message}
                fullWidth
                placeholder="Title"
              />
              <Input
                label="Meta Title (Optional for SEO)"
                config={{
                  ...register('metaTitle'),
                }}
                descriptionText={errors.metaTitle?.message}
                isError={!!errors.metaTitle?.message}
                fullWidth
                placeholder="Meta Title (Optional for SEO)"
              />
              <Input
                label="Markdown"
                config={{
                  ...register('markdown'),
                }}
                descriptionText={errors.markdown?.message}
                isError={!!errors.markdown?.message}
                fullWidth
                placeholder="Markdown"
              />
              <Input
                label="Meta Description (Optional for SEO)"
                config={{
                  ...register('metaDescription'),
                }}
                descriptionText={errors.metaDescription?.message}
                isError={!!errors.metaDescription?.message}
                fullWidth
                placeholder="Title"
              />
              <div className="block space-y-6 md:space-y-0 md:grid grid-cols-2 gap-6 ">
                {categories && (
                  <Select
                    label="Category"
                    config={{ ...register('categoryId') }}
                    options={categories.map(category => {
                      return {
                        label: category.name,
                        value: category.id,
                      }
                    })}
                    descriptionText={errors.categoryId?.message}
                    isError={!!errors.categoryId?.message}
                  />
                )}
                {countries && (
                  <Select
                    label="Country"
                    config={{ ...register('countryCode') }}
                    options={countries?.map(country => {
                      return {
                        label: country.label,
                        value: country.id,
                      }
                    })}
                    descriptionText={errors.countryCode?.message}
                    isError={!!errors.countryCode?.message}
                  />
                )}
                <DatePicker
                  label="Publish at"
                  onChange={(date: string) => setValue('publishedDate', date)}
                  value={watch('publishedDate')}
                  fullWidth
                />
              </div>
              <div>
                <Editor
                  label="Content"
                  value={watch('html') || ''}
                  onChange={(html: string) => setValue('html', html)}
                  descriptionText={errors.html?.message}
                  isError={!!errors.html?.message}
                  imageHandler={imageHandler}
                />
              </div>
              <div>
                <TagList
                  tags={tags || []}
                  onSetTags={(tagsValue: string[]) => setTags(tagsValue)}
                  label="Tags"
                  html={watch('html') || ''}
                />
                {errors['tags']?.message && <p>{errors['tags']?.message}</p>}
              </div>
              <div>
                <Reference
                  references={newsReferences || []}
                  onGenReferences={handleGenerateReference}
                  label="Reference Link"
                />
                {errors['tags']?.message && <p>{errors['tags']?.message}</p>}
              </div>
              <div className="flex justify-between">
                <Link to={`/journalist/group/${groupId}/news`}>
                  <Button variant="outlined">Back</Button>
                </Link>
                <Button type="submit">Create</Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </Box>
  )
}

export default withAuth(CreateNewsPage)
