import {
  BrowserRouter,
  Navigate,
  Routes as ReactRoutes,
  Route,
} from 'react-router-dom'

import CategoryPage from 'pages/news/category'
import CreateCategoryPage from 'pages/news/category/create'
import CreateGroupJournalistPage from 'pages/journalist/group/create'
import CreateNewsCountryPage from 'pages/news/country/create'
import CreateNewsPage from 'pages/journalist/group/news/create'
import DashboardPage from 'pages/dashboard'
import EditNewsPage from 'pages/journalist/group/news/edit'
import InviteMemberGroupJournalistPage from 'pages/journalist/group/member/invite'
import JournalistDetailPage from 'pages/journalist/group/index'
import JournalistNewsPage from 'pages/journalist/group/news'
import JournalistPage from 'pages/journalist'
import Layout from 'components/Layout'
import NewsCountryPage from 'pages/news/country'
import NewsPage from 'pages/news'
import SignInPage from 'pages/sign-in'
import UserPage from 'pages/users'
import UserDetailPage from 'pages/users/detail'
import NewsCategoryEditPage from 'pages/news/category/edit'
import EditNewsCountryPage from 'pages/news/country/edit'

const Routes = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/sign-in" />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="user">
            <Route index element={<UserPage />} />
            <Route path=":userId" element={<UserDetailPage />} />
          </Route>
          <Route path="journalist">
            <Route index element={<JournalistPage />} />
            <Route path="group">
              <Route path="create" element={<CreateGroupJournalistPage />} />
              <Route path=":groupId">
                <Route index element={<JournalistDetailPage />} />
                <Route path="member">
                  <Route
                    path="invite"
                    element={<InviteMemberGroupJournalistPage />}
                  />
                </Route>
                <Route path="news">
                  <Route index element={<JournalistNewsPage />} />
                  <Route path="create" element={<CreateNewsPage />} />
                  <Route path=":newsId">
                    <Route path="edit" element={<EditNewsPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="news">
            <Route index element={<NewsPage />} />
            <Route path="category">
              <Route index element={<CategoryPage />} />
              <Route path="create" element={<CreateCategoryPage />} />
              <Route
                path=":categoryId/edit"
                element={<NewsCategoryEditPage />}
              />
            </Route>
            <Route path="country">
              <Route index element={<NewsCountryPage />} />
              <Route path="create" element={<CreateNewsCountryPage />} />
              <Route path=":countryId" element={<EditNewsCountryPage />} />
            </Route>
          </Route>
        </Route>
      </ReactRoutes>
    </BrowserRouter>
  )
}

export default Routes
