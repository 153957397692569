import {
  FaBars,
  FaFeather,
  FaHome,
  FaNewspaper,
  FaUser,
  FaQuestionCircle as FaQuestion,
} from 'react-icons/fa'
import { Navbar, Sidebar } from '@moxga/ui-components'
import React, { useState } from 'react'

import { AppStoreContext } from 'store/AppStore/AppStore'
import { Outlet } from 'react-router-dom'
import { useContext } from 'react'

const menu = [
  {
    icon: <FaHome />,
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    icon: <FaUser />,
    label: 'Users',
    href: '/user',
  },
  {
    icon: <FaFeather />,
    label: 'Journalists',
    href: '/journalist',
  },
  {
    icon: <FaNewspaper />,
    label: 'News',
    prefix: '/news',
    subMenu: [
      {
        label: 'Categories',
        href: '/news/category',
      },
      {
        label: 'Countries',
        href: '/news/country',
      },
    ],
  },
  {
    icon: <FaQuestion />,
    label: 'Quiz',
    prefix: '/quiz',
    subMenu: [
      {
        label: 'Categories',
        href: '/quiz/category',
      },
      {
        label: 'Quiz',
        href: '/quiz',
      },
    ],
  },
]
interface Props {}

const Layout: React.FC<React.PropsWithChildren<Props>> = ({}) => {
  const { user } = useContext(AppStoreContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div
      className={`flex h-screen ${
        isOpen ? 'overflow-hidden lg:overflow-auto' : 'overflow-auto'
      }`}
    >
      {/* sidebar mobile */}
      <div className="fixed h-screen z-[99] lg:hidden">
        <div
          className={`relative  ${
            isOpen ? 'h-screen w-screen overflow-hidden' : 'h-0 w-0 delay-300'
          }`}
        >
          <div
            className={`bg-black/20 h-full w-full`}
            onClick={() => setIsOpen(false)}
          ></div>
          <div
            id="sidebar"
            className={`w-[260px] h-full absolute top-0 ${
              isOpen ? 'left-0' : 'left-[-260px]'
            } transition-all duration-300`}
          >
            <Sidebar logo="https://i.imgur.com/BAQdw73.png" menu={menu} />
          </div>
        </div>
      </div>
      {/* sidebar desktop */}
      <div className="hidden lg:block w-[260px] h-full">
        <Sidebar logo="https://i.imgur.com/BAQdw73.png" menu={menu} />
      </div>
      <div className="w-full h-screen overflow-y-auto lg:w-[calc(100%_-_260px)]">
        <div className="px-[30px] py-[15px] w-full max-w-[1200px] mx-auto">
          <div className="relative w-full h-full">
            {user && (
              <div className="sticky w-full top-[15px] mb-[15px] z-[98]">
                <div className="relative">
                  <Navbar
                    user={{
                      name: user?.displayName || '',
                      bio: user?.bio || '',
                      avatar: user?.avatarUrl || '',
                    }}
                  />
                  <button
                    id="open-sidebar"
                    className="absolute top-1/2 left-[20px] -translate-y-1/2 lg:hidden"
                    onClick={() => setIsOpen(isOpen => !isOpen)}
                  >
                    <FaBars />
                  </button>
                </div>
              </div>
            )}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
