import { ReactNode } from "react";
import { defaultTheme } from "../../configs/theme";
import styled from "styled-components";

interface Props {
    type: 'success' | 'danger'
    children: ReactNode
}

const titles = {
    success: 'Success',
    danger: 'Error',
}

const Alert: React.FC<React.PropsWithChildren<Props>> = ({ type, children }) => {
    return (
        <AlertStyle type={type}>
            <div className="content">
                <h3 className="title">{titles[type]}!</h3>
                <p>{children}</p>
            </div>
        </AlertStyle>
    )
}

interface AlertStyleProps {
    type: 'success' | 'danger'
}

const AlertStyle = styled.div<AlertStyleProps>`
  width: 620px;
  max-width: 90%;
  background: white;
  border-radius: 5px;
  overflow: hidden;

  .content {
    background: ${(props) =>
      props.theme.color?.[props.type] || defaultTheme.color?.[props.type]}30;
    color: ${(props) =>
      props.theme.color?.[props.type] || defaultTheme.color?.[props.type]};
    padding: 15px 20px;

    .title {
      font-weight: bold;
    }
`

export default Alert
