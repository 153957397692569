import { Alert, Button, useModal } from '@moxga/ui-components'
import { NewsService } from '@moxga/services'
import { ref } from 'yup'
import toast from 'react-hot-toast'
import { useState } from 'react'

const ApprovalModal = ({
  newsId,
  groupId,
  nextApproveText,
  refetch,
}: {
  newsId: number
  groupId: string
  nextApproveText: string
  refetch?: () => void
}) => {
  const [loading, setLoading] = useState(false)
  const modal = useModal()
  const confirmApprove = async () => {
    setLoading(true)
    const result = await NewsService.journalist.approveNews(groupId, newsId)
    setLoading(false)
    if (!result.success) {
      toast.custom(<Alert type={'danger'}>{result.message}</Alert>)
      return
    }

    toast.custom(<Alert type={'success'}>{result.message}</Alert>)

    if (refetch) {
      refetch()
    }
    modal.onCloseModal()
  }
  const cancel = () => {
    if (refetch) {
      refetch()
    }
    modal.onCloseModal()
  }
  return (
    <div>
      <div className="bg-gray-100 rounded p-2">
        <p>
          อนุมัติข่าวเพื่อทำให้ข่าวสามารถเผยแพร่ได้ <b>ประกอบไปด้วย 2 รูปแบบ</b>
          <ul className="ml-4">
            <li>
              1. อนุมัติชั่วคราว 24 ชั่วโมง <br />{' '}
              <span className={'ml-6'}>
                ข่าวจะสามารถแสดงบนหน้าเว็บได้เพียง 24 ชั่วโมงเท่านั้น
              </span>
            </li>
            <li>
              2. อนุมัติถาวร
              <br />
              <span className={'ml-6'}>ข่าวจะแสดงบนหน้าเว็บได้ตลอด</span>
            </li>
          </ul>
        </p>
      </div>
      <div className={'py-5 text-center'}>
        <h3 className="text-xl">หากคุณยืนยันที่จะเปลียนสถานะไปเป็น</h3>
        <span className={'bg-gray-200 mt-2 p-1 rounded text-lg font-bold'}>
          {nextApproveText}
        </span>
      </div>
      <div className="flex justify-between mt-10">
        <Button color="danger" onClick={() => cancel()}>
          ยกเลิก
        </Button>
        <Button
          color="primary"
          onClick={() => confirmApprove()}
          disabled={loading}
        >
          ยืนยันอนุมัติ
        </Button>
      </div>
    </div>
  )
}

export default ApprovalModal
