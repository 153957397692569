import * as Yup from 'yup'

import { Alert, Box, Button, Input, Select } from '@moxga/ui-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { NewsJournalistClassEnum, NewsService } from '@moxga/services'
import { useEffect, useState } from 'react'

import { IAddMemberGroupJournalistBody } from '@moxga/services/src/types/request'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'

const { yupResolver } = require('@hookform/resolvers/yup')

const InviteMemberGroupJournalistPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [levels, setLevels] = useState<
    {
      label: string
      value: number
    }[]
  >([])
  let navigate = useNavigate()
  let { groupId } = useParams()
  const [submitting, SetSubmitting] = useState(false)
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('จำเป็นต้องกรอก Email'),
    level: Yup.string().required('จำเป็นต้องเลือก Role'),
  })
  const formOptions = { resolver: yupResolver(validationSchema) }
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IAddMemberGroupJournalistBody>(formOptions)

  useEffect(() => {
    const result = (
      Object.keys(NewsJournalistClassEnum).filter((v: string) =>
        isNaN(Number(v))
      ) as (keyof typeof NewsJournalistClassEnum)[]
    ).map((key, index) => {
      return {
        label: key,
        value: NewsJournalistClassEnum[key],
      }
    })
    setLevels(result)
    setIsLoading(false)
  }, [])

  const onSubmit = handleSubmit(async data => {
    SetSubmitting(true)
    const result = await NewsService.journalist.inviteMember(
      groupId as string,
      data
    )
    SetSubmitting(false)
    // success case
    if (result.success) {
      navigate('/journalist')
      return
    }
    // failed case
    if (!result.success) {
      if (result.errors) {
        setError('level', { message: result.errors['level'] })
        setError('email', { message: result.errors['email'] })
      } else {
        // TODO: show toast
        toast.custom(<Alert type="danger">{result.message}</Alert>)
      }
    }
  })

  return (
    <Box>
      <div className="p-5">
        {isLoading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <form onSubmit={onSubmit} className="space-y-4">
            <Input
              placeholder="example@mail.com"
              config={{ ...register('email') }}
              label="Email to invite"
              descriptionText={errors.email?.message}
              isError={!!errors.email?.message}
              disabled={submitting}
            />
            <Select
              options={levels.map(x => ({
                label: x.label,
                value: x.value,
              }))}
              config={{ ...register('level') }}
              isError={!!errors?.level?.message}
              descriptionText={errors.level?.message}
              disabled={submitting}
            />
            <div className="flex justify-between">
              <Link to={`/journalist/group/${groupId}`}>
                <Button type="button" variant="outlined">
                  Back
                </Button>
              </Link>
              <Button type="submit" disabled={submitting}>
                Create
              </Button>
            </div>
          </form>
        )}
      </div>
    </Box>
  )
}

export default InviteMemberGroupJournalistPage
