import { ITheme, TThemeColor } from '../../interfaces/theme'
import styled, { useTheme } from 'styled-components'

import { ReactNode } from 'react'
import { defaultTheme } from '../../configs/theme'
import { useMemo } from '@storybook/addons'

interface Props {
  size?: 'sm' | 'md' | 'lg' | 'xl'
  width?: number
  height?: number
  color?: TThemeColor
  light?: boolean
  icon?: ReactNode
  imageURL?: string
  status?: 'online' | 'offline' | 'away' | 'busy'
}

const Avatar: React.FC<React.PropsWithChildren<Props>> = ({
  size = 'md',
  width,
  height,
  color = 'primary',
  light = false,
  icon,
  imageURL,
  status,
}) => {
  return (
    <AvatarWrapper
      width={width || AvatarSizes[size].width}
      height={height || AvatarSizes[size].height}
      color={color}
      light={light}
    >
      {imageURL && <img src={imageURL} alt="avatar" className="img-cover" />}
      {icon && <span>{icon}</span>}
    </AvatarWrapper>
  )
}

let AvatarSizes = {
  sm: {
    width: 24,
    height: 24,
  },
  md: {
    width: 32,
    height: 32,
  },
  lg: {
    width: 50,
    height: 50,
  },
  xl: {
    width: 70,
    height: 70,
  },
}

const AvatarStyles = (color: TThemeColor, light: boolean) => {
  const theme: ITheme = useTheme()
  const colorValue: string =
    theme?.color?.[color] || (defaultTheme.color?.[color] as string)
  if (light) {
    return {
      color: 'white',
      backgroundColor: colorValue,
    }
  } else {
    return { color: colorValue, backgroundColor: `${colorValue}20` }
  }
}

interface AvatarWrapperProps {
  width: number
  height: number
  color: TThemeColor
  light: boolean
}

const AvatarWrapper = styled.div<AvatarWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border-radius: 999px;
  overflow: hidden;

  ${props => AvatarStyles(props.color, props.light)}

  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default Avatar
