import Menu from './Menu'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  menu: {
    icon?: ReactNode
    label: string
    href?: string
    subMenu?: {
      icon?: ReactNode
      label: string
      href: string
    }[]
  }[]
}

const MenuList: React.FC<React.PropsWithChildren<Props>> = ({ menu }) => {
  return (
    <MenuListStyle>
      {menu.map((i, k) => {
        return <Menu key={i.label} {...i} />
      })}
    </MenuListStyle>
  )
}

const MenuListStyle = styled.ul`
  list-style-type: none;
`
export default MenuList
