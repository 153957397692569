import { Box, Button, ModalProvider, useModal } from '@moxga/ui-components'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAsync } from '@moxga/utils'
import { INewsCategory, NewsService } from '@moxga/services'
import {
  CheckIcon,
  DotsHorizontalIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/solid'

type IItemProps = {
  name: string
  id: string
  onDelete: (id: string) => void
}
const Item = ({ name, onDelete, id }: IItemProps) => {
  return (
    <div className="col-span-1 p-2 rounded bg-gray-100 hover:bg-gray-200 cursor-pointer transition-all">
      <p className="text-gray-900">
        {name}{' '}
        <TrashIcon
          onClick={() => onDelete(id)}
          className="transition-all text-red-400 hover:text-red-500 h-6 w-6 inline-block float-right"
        />
      </p>
    </div>
  )
}

const AddCategoryModalContent = ({
  alreadyAdded,
  groupId,
  refetch,
}: {
  alreadyAdded: INewsCategory[]
  groupId: string
  refetch: () => void
}) => {
  const { data, error, isLoading, execute } = useAsync({
    asyncFn: () => NewsService.listCategory(),
    isImmediate: true,
  })
  const [loading, setLoading] = useState(false)

  // id list of category
  const [selected, setSelected] = useState<string[]>([])

  const onSelect = (categoryId: string) => {
    setSelected(ids => {
      const list = new Set(ids)
      if (list.has(categoryId)) {
        list.delete(categoryId)
      } else {
        list.add(categoryId)
      }
      return Array.from(list)
    })
  }
  const { onCloseModal } = useModal()
  const submit = async () => {
    setLoading(true)
    const jobs: Promise<any>[] = []
    for (const id of selected) {
      jobs.push(NewsService.journalist.addCategoryToGroup(groupId, id))
    }
    await Promise.all(jobs)
    refetch()
    execute()
    setLoading(false)
    onCloseModal()
  }

  if (isLoading) {
    return (
      <div className="flex flex-col space-y-2">
        {[...Array(5)].map(() => (
          <div className="h-10 bg-gray-100 rounded hover:bg-gray-200 cursor-pointer "></div>
        ))}
      </div>
    )
  }
  if (error) {
    return <p>error: {error}</p>
  }
  const unselected = data.filter(a => !alreadyAdded.find(b => a.id === b.id))

  return (
    <div className="flex flex-col space-y-2">
      {unselected.length === 0 && (
        <div className="my-24 text-center">
          <p className="text-lg">คุณได้เพิ่มหมวดหมู่จนหมดแล้ว TT</p>
        </div>
      )}
      {unselected.map(item => (
        <div
          key={item.id}
          className={`p-2 bg-gray-100 rounded hover:bg-gray-200 cursor-pointer `}
          onClick={() => {
            onSelect(item.id)
          }}
        >
          <span className="text-md flex">
            {item.name}{' '}
            {selected.find(id => item.id === id) && (
              <span className="inline-block ml-auto">
                <CheckIcon className="h-6 w-6 text-green-500" />
              </span>
            )}
          </span>
        </div>
      ))}
      <div className="flex justify-between space-x-2 pt-4">
        <Button fullWidth color="danger" onClick={() => onCloseModal()}>
          <XIcon className="h-4 w-4 inline-block mr-2" />
          ยกเลิก
        </Button>
        <Button
          disabled={unselected.length === 0 || loading}
          fullWidth
          color="success"
          onClick={() => submit()}
        >
          {!loading && <CheckIcon className="h-4 w-4 inline-block mr-2" />}
          {loading && (
            <DotsHorizontalIcon className="h-4 w-4 inline-block mr-2 animate-bounce" />
          )}
          {loading ? 'ยืนยัน' : 'กำลังเพิ่ม'}
        </Button>
      </div>
    </div>
  )
}

const CategorySection = ({ groupId }: { groupId: string }) => {
  const { data, error, isLoading, execute } = useAsync({
    asyncFn: () => NewsService.journalist.listCategory(groupId),
    isImmediate: true,
  })
  const modal = useModal()
  const openAddCategory = () => {
    modal.onOpenModal({
      header: <span className="">เพิ่มหมวดหมู่สำหรับกลุ่มนี้</span>,
      body: (
        <AddCategoryModalContent
          alreadyAdded={data || []}
          groupId={groupId}
          refetch={execute}
        />
      ),
    })
  }
  const onDelete = (categoryId: string) => {
    NewsService.journalist
      .removeCategoryFromGroup(groupId, categoryId)
      .finally(() => {
        execute()
      })
  }

  if (error) {
    return <p>error: {error}</p>
  }
  return (
    <Box>
      <div className="p-5 space-y-6">
        <div className="flex justify-between items-center">
          <h3 className="font-bold">Categories</h3>
          <Button onClick={() => openAddCategory()}>เพิ่มหมวดหมู่ใหม่</Button>
        </div>
        <div className="grid gap-2 grid-cols-4">
          {isLoading &&
            [...Array(4)].map(() => (
              <div className="h-10 w-full bg-gray-100 rounded" />
            ))}
          {!isLoading &&
            data.map(item => (
              <Item
                key={item.id}
                name={item.name}
                id={item.id}
                onDelete={onDelete}
              />
            ))}
        </div>
        {!isLoading && data.length === 0 && (
          <p className="p-4 text-lg text-red-500 text-center">
            คุณยังไม่เพิ่มหมวดหมู่
          </p>
        )}
      </div>
    </Box>
  )
}

export default CategorySection
