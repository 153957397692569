import hoistNonReactStatics from './hoist-non-react-statics'

import wrapDisplayName from './wrapDisplayName'
import setDisplayName from './setDisplayName'
import mapProps from './mapProps'

const withProps = input => BaseComponent => {
  const WithProps = mapProps(props => ({
    ...props,
    ...(typeof input === 'function' ? input(props) : input),
  }))(BaseComponent)

  hoistNonReactStatics(WithProps, BaseComponent)

  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(BaseComponent, 'withProps'))(
      WithProps
    )
  }

  return WithProps
}

export default withProps
