import Button from '../Button'
import styled from 'styled-components'
import Input from '../Input'

interface Props {
  references: { label: string; link: string }[]
  onGenReferences: (references: { label: string; link: string }[]) => void
  label: string
}

const Reference: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  references,
  onGenReferences,
}) => {
  const removeReferences = (index: number) => {
    onGenReferences(references.filter((ref, i) => index != i))
  }
  const onChangeLabelValue = (index: number, value: string) => {
    let refs = [...references]
    let item = { ...refs[index] }
    item.label = value
    refs[index] = item
    onGenReferences(refs)
  }
  const onChangeLinkValue = (index: number, value: string) => {
    let refs = [...references]
    let item = { ...refs[index] }
    item.link = value
    refs[index] = item
    onGenReferences(refs)
  }

  return (
    <TagListWrapper>
      <div className="label-wrapper">
        <label>{label}</label>
        <Button
          onClick={() => {
            onGenReferences([...references, { label: '', link: '' }])
          }}
        >
          More Ref.
        </Button>
      </div>
      {references.map((ref, index) => (
        <div key={index} className="input-references-wrapper">
          <Input
            config={{
              value: ref.label,
              onChange: e => onChangeLabelValue(index, e.target.value),
              key: index,
            }}
            label="label"
            fullWidth
          />
          <Input
            config={{
              value: ref.link,
              onChange: e => onChangeLinkValue(index, e.target.value),
              key: index,
            }}
            label="link"
            fullWidth
          />
          <Button
            style={{
              marginLeft: '16px',
              marginTop: '26px',
              backgroundColor: 'red',
              width: '20px',
              fontSize: '10px',
            }}
            onClick={() => {
              removeReferences(index)
            }}
          >
            remove
          </Button>
        </div>
      ))}
    </TagListWrapper>
  )
}

const TagListWrapper = styled.div`
  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .input-references-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
`

export default Reference
