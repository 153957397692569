import React from 'react'
import Row from './Row'
import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'

type PrimitiveType = string | number | boolean

function noSymbol(value: any): PrimitiveType {
  if (typeof value === 'symbol') {
    return ''
  }
  return value
}

type MinTableItem = { id: string | number }

type Props<T extends MinTableItem> = {
  headers: {
    thStyle?: {}
    tdStyle?: {}
    render?: (value: any, index: number, item: T) => React.ReactNode
    key: keyof T
    label?: string
    style?: {}
  }[]
  data: T[]
  isLoading?: boolean
  actions?: {
    onDelete?: (item: any, index: number) => void
    onEdit?: (item: any, index: number) => void
    onViewDetail?: (item: any, index: number) => void
    others?: {
      icon: React.ReactNode
      label: string
      onClick: (item: any, index: number) => void
    }[]
  }
}

const Table = <T extends MinTableItem>({
  data,
  headers,
  isLoading,
  actions,
}: Props<T>) => {
  return (
    <>
      {isLoading ? (
        <div className="text-center p-[20px]">Loading...</div>
      ) : (
        <>
          {data.length === 0 ? (
            <div className="text-center p-[20px]">
              There are no records to display
            </div>
          ) : (
            <TableWrapperStyle>
              <table className="table">
                <thead>
                  <tr className="tr-header">
                    {headers.map((t, index) => (
                      <th key={index} className={'th-header'} style={t.thStyle}>
                        {t.label || noSymbol(t.key)}
                      </th>
                    ))}
                    {(actions?.onDelete ||
                      actions?.onEdit ||
                      actions?.onViewDetail) && (
                      <th className="th-header-actions">ACTIONS</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <Row
                        key={index}
                        index={index}
                        headers={headers}
                        item={item}
                        actions={actions}
                        isBottom={
                          index >= 2 &&
                          index >= data.length / 2 &&
                          index >= data.length - 5
                        }
                      />
                    )
                  })}
                </tbody>
              </table>
            </TableWrapperStyle>
          )}
        </>
      )}
    </>
  )
}

const TableWrapperStyle = styled.div`
  width: 100%;
  overflow-x: auto;

  .table {
    table-layout: auto;
    width: 100%;
    border: 1px solid ${defaultTheme['color-system']['border-table']};

    .tr-header {
      background-color: ${props =>
        props.theme.color.primary || defaultTheme.color?.primary}20;
      text-align: left;
      border: 1px solid ${defaultTheme['color-system']['border-table']};

      .th-header {
        padding: 10px 20px;
        text-transform: uppercase;
      }

      .th-header-actions {
        padding: 10px 20px;
        text-align: right;
      }
    }
  }
`

export default Table
