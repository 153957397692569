import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

const withNotAuth = (WrappedComponent: any) => (props: any) => {
  let navigate = useNavigate()
  const [isReady, setIsReady] = useState<boolean>(false)
  const accessToken = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')

  useEffect(() => {
    if (accessToken && refreshToken) {
      navigate('/dashboard')
    } else {
      setIsReady(true)
    }
  }, [])

  if (!isReady) {
    return <div></div>
  }
  return <WrappedComponent {...props} />
}

export default withNotAuth
