import {
  ICreateNewsCountryBody,
  NewsService,
  UserPermissionEnum,
} from '@moxga/services'
import { Box } from '@moxga/ui-components'
import { compose, useAsync, withProps } from '@moxga/utils'
import withAuth from 'hoc/withAuth'
import withPermission from 'hoc/withPermission'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CreateEditNewsCountryForm from './components/CreateEditNewsCountryForm'

interface ICountryData extends ICreateNewsCountryBody {
  id: number
}

interface Props {
  countryId: number
  countryData: ICountryData
  isLoading: boolean
}

const EditNewsCountryPage: React.FC<React.PropsWithChildren<Props>> = ({
  countryId,
  countryData,
  isLoading,
}) => {
  return (
    <Box>
      <div className="p-5">
        #{countryId}
        {!isLoading && (
          <CreateEditNewsCountryForm
            countryId={countryId}
            countryData={countryData}
          />
        )}
      </div>
    </Box>
  )
}

export default compose(
  withAuth,
  withPermission({
    requiredPermissions: [UserPermissionEnum.NEWS_COUNTRY_EDIT],
  }),
  withProps(() => {
    const { countryId } = useParams()

    const { data, isLoading, execute } = useAsync({
      asyncFn: () => NewsService.findCountryCodeById(+countryId!),
      isImmediate: false,
      initialData: null,
    })

    useEffect(() => {
      if (countryId) {
        execute()
      }
    }, [countryId])

    return { countryId, countryData: data, isLoading }
  })
)(EditNewsCountryPage)
