import { Link } from 'react-router-dom'
import { Button } from '@moxga/ui-components'
import { FaCog } from 'react-icons/fa'
import withPermission from 'hoc/withPermission'
import { UserPermissionEnum } from '@moxga/services'

interface Props {
  userId: string
}

const EditUserButton: React.FC<React.PropsWithChildren<Props>> = ({ userId }) => {
  return (
    <Link to={`/user/${userId}`}>
      <Button>
        <FaCog />
      </Button>
    </Link>
  )
}

export default withPermission({
  noPermissionsRenderNothing: true,
  requiredPermissions: [UserPermissionEnum.USER_READ],
})(EditUserButton)
