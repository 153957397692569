import { Alert, Box, Button, Input, Table } from '@moxga/ui-components'
import {
  CheckPermission,
  INewsCategory,
  NewsService,
  UserPermissionEnum,
} from '@moxga/services'

import { Link, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useContext, useEffect, useState } from 'react'
import withAuth from 'hoc/withAuth'
import { compose } from '@moxga/utils'
import withPermission from '../../../hoc/withPermission'
import { AppStoreContext } from '../../../store/AppStore/AppStore'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'

const CategoryPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [categories, setCategories] = useState<INewsCategory[]>([])
  const { user } = useContext(AppStoreContext)
  const [sorting, setSorting] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string | undefined>()
  const navigate = useNavigate()
  useEffect(() => {
    fetchCategories()
  }, [searchText])

  const fetchCategories = async () => {
    const result = await NewsService.listCategory(searchText)
    if (result.success) {
      //success case
      setCategories(result.data)
      setIsLoading(false)
      return
    } else {
      //failed case
      toast.custom(<Alert type="danger">{result.message}</Alert>)
      setIsLoading(false)
      return
    }
  }

  const onDelete = (item: INewsCategory, index: number) => {
    if (
      user &&
      CheckPermission(user.permission, [
        UserPermissionEnum.NEWS_CATEGORY_DELETE,
      ])
    ) {
      NewsService.deleteCategory(item.id)
        .then(res => {
          if (!res.success) {
            toast.custom(<Alert type="danger">{res.message}</Alert>)
          } else {
            toast.custom(<Alert type="success">ลบสำเร็จ</Alert>)
          }
        })
        .finally(() => {
          fetchCategories()
        })
      return
    }
    toast.error('คุณไม่สามารถดำเนินการดังกล่าวได้')
  }

  const onEdit = (item: INewsCategory, index: number) => {
    if (
      user &&
      CheckPermission(user.permission, [
        UserPermissionEnum.NEWS_CATEGORY_DELETE,
      ])
    ) {
      navigate(`/news/category/${item.id}/edit`)
      return
    }
    toast.error('คุณไม่สามารถดำเนินการดังกล่าวได้')
  }
  const handleGoUp = (item: INewsCategory) => {
    setSorting(true)
    NewsService.editCategory(item.id, {
      name: item.name,
      sortOrder: item.sortOrder + 1,
    }).finally(() => {
      fetchCategories().finally(() => {
        setSorting(false)
      })
    })
  }
  const handleGoDown = (item: INewsCategory) => {
    setSorting(true)
    NewsService.editCategory(item.id, {
      name: item.name,
      sortOrder: item.sortOrder - 1,
    }).finally(() => {
      fetchCategories().finally(() => {
        setSorting(false)
      })
    })
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  return (
    <Box>
      <div className="p-[20px] flex space-x-4 justify-end items-center">
        <span className="flex items-center">
          <span className="mr-4">Search: </span>
          <div>
            <Input
              placeholder="ค้นหา"
              config={{
                onChange: e => handleSearch(e),
              }}
            />
          </div>
        </span>
        {user &&
          CheckPermission(user.permission, [
            UserPermissionEnum.NEWS_CATEGORY_CREATE,
          ]) && (
            <Link to={'/news/category/create'}>
              <Button type="button" style={{ height: '39px' }}>
                Create a news category
              </Button>
            </Link>
          )}
      </div>
      <Table<INewsCategory>
        headers={[
          {
            label: 'Title',
            key: 'name',
            render(value) {
              return (
                <a
                  href={
                    'https://ikizzy.com/news/category/' +
                    encodeURIComponent(value)
                  }
                  target={'_blank'}
                  rel="noreferrer"
                  className="hover:text-primary "
                >
                  {value}
                </a>
              )
            },
          },
          {
            label: 'Order',
            key: 'sortOrder',
            thStyle: {
              textAlign: 'center',
            },
            tdStyle: {
              textAlign: 'center',
            },
            render(value, index, row) {
              return (
                <div className="flex justify-center">
                  <Button
                    disabled={sorting || index === 0}
                    onClick={() => handleGoUp(row)}
                    size="xs"
                  >
                    <ArrowUpIcon className={'h-4 w-4'} />
                  </Button>{' '}
                  <span className="font-bold mx-2">/</span>{' '}
                  <Button
                    disabled={sorting || index === categories.length - 1}
                    onClick={() => handleGoDown(row)}
                    size="xs"
                  >
                    <ArrowDownIcon className={'h-4 w-4'} />
                  </Button>
                </div>
              )
            },
          },
        ]}
        data={categories}
        isLoading={isLoading}
        actions={{ onDelete: onDelete, onEdit: onEdit }}
      />
    </Box>
  )
}

export default compose(
  withAuth,
  withPermission({
    noPermissionsRenderNothing: true,
    requiredPermissions: [],
  })
)(CategoryPage)
