import { ReactNode, useEffect, useMemo, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { FaChevronRight } from 'react-icons/fa'
import Menu from './Menu'
import styled from 'styled-components'

interface Props {
  icon: ReactNode
  label: string
  prefix: string
  subMenu: {
    icon?: ReactNode
    label: string
    href: string
  }[]
}

const MenuWithSubmenu: React.FC<React.PropsWithChildren<Props>> = ({ icon, label, prefix, subMenu }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { pathname } = useLocation()

  const isMatch = useMemo(() => {
    return !!matchPath(prefix + '/*', pathname)
  }, [prefix, pathname])

  useEffect(() => {
    if (isMatch) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [isMatch])

  return (
    <MenuWithSubmenuWrapperStyle
      isOpen={isOpen}
      onClick={() => setIsOpen(isOpen => !isOpen)}
    >
      <li className="menu">
        <span className="icon-label">
          <span className="icon">{icon}</span>
          <span className="label">{label}</span>
        </span>
        <span className="arrow">
          <FaChevronRight />
        </span>
      </li>
      <div className="submenu">
        <ul className="submenu-list">
          {subMenu.map((menu, k) => {
            return (
              <Menu
                key={`${label}-${menu.label}`}
                {...menu}
                href={menu.href as string}
              />
            )
          })}
        </ul>
      </div>
    </MenuWithSubmenuWrapperStyle>
  )
}

interface MenuWithSubmenuWrapperStyleProps {
  isOpen: boolean
}

const MenuWithSubmenuWrapperStyle = styled.div<MenuWithSubmenuWrapperStyleProps>`
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;
    cursor: pointer;
    background: ${props => (props.isOpen ? '#F8F8F8' : '')};
    border-radius: 5px;

    .icon-label {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 15px;
        transition: all;
        transition-duration: 0.3s;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }

    .arrow {
      transform: ${props => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
      transition: transform 0.3s;
    }

    &:hover {
      .icon-label {
        .icon {
          margin-left: 5px;
          margin-right: ${props => (props.isOpen ? '15px' : '10px')};
        }
      }
    }
  }
  .submenu {
    max-height: ${props => (props.isOpen ? '500px' : '0px')};
    visibility: ${props => (props.isOpen ? 'visible' : 'invisible')};
    transition: all 0.5s;
    .submenu-list {
      opacity: ${props => (props.isOpen ? '1' : '0')};
      transition-delay: ${props => (props.isOpen ? '0.1s' : '0s')};
    }
  }
`

export default MenuWithSubmenu
