import {
  IAddMemberGroupJournalistBody,
  ICreateGroupJournalistBody,
  ICreateNewsCategoryBody,
  ICreateNewsCountryBody,
  IEditNewsCategoryBody,
  INewsSearchQuery,
  IPaginationRequest,
} from '../types/request'
import {
  IJournalistGroupItem,
  IJournalistGroupStatistics,
  INewsCard,
  INewsCategory,
  INewsCommentCard,
  INewsCountry,
  INewsDetail,
  INewsDetailForm,
  INewsListItem,
  INewsUserInteraction,
  NewsFeelingEnum,
  PaginationSortEnum,
  PaginationQuery,
} from '../types'

import { IPaginationResponse } from '../types/response'
import { fetcher } from '../libs/fetcher'

export const NewsService = {
  journalist: {
    check(userId: string) {
      return fetcher.get<boolean>(`/api/news/journalist/check/${userId}`)
    },
    fetchNews({ userId, page, limit }: { userId: string } & PaginationQuery) {
      return fetcher.get<IPaginationResponse<INewsListItem>>(
        `/api/news/journalist/news/${userId}`,
        {
          params: {
            page,
            limit,
          },
        }
      )
    },
    statistics(groupId: string) {
      return fetcher.get<IJournalistGroupStatistics>(
        `/api/news/journalist/${groupId}/statistics`
      )
    },
    fetchGroups(q: { search?: string }) {
      return fetcher.get<IJournalistGroupItem[]>(`/api/news/journalist`, {
        params: q,
      })
    },
    createGroup(data: ICreateGroupJournalistBody) {
      return fetcher.post<IJournalistGroupItem>(`/api/news/journalist`, data)
    },
    detailGroup(groupId: string) {
      return fetcher.get<IJournalistGroupItem>(
        `/api/news/journalist/${groupId}`
      )
    },
    addCategoryToGroup(groupId: string, categoryId: string) {
      return fetcher.post<{}>(
        `/api/news/journalist/${groupId}/categories/${categoryId}`
      )
    },
    removeCategoryFromGroup(groupId: string, categoryId: string) {
      return fetcher.delete<{}>(
        `/api/news/journalist/${groupId}/categories/${categoryId}`
      )
    },
    listCategory(groupId: string) {
      return fetcher.get<INewsCategory[]>(
        `/api/news/journalist/${groupId}/categories`
      )
    },

    // head only
    inviteMember(group_id: string, data: IAddMemberGroupJournalistBody) {
      return fetcher.post<{}>(`/api/news/journalist/${group_id}/member`, {
        level: Number(data.level),
        email: data.email,
      })
    },
    // head only
    editMember() {},
    removeMember() {},

    // news bo
    // list news in journalist group
    listNews(
      groupId: string,
      q: {
        page: number
        limit: number
        sort?: PaginationSortEnum
        search: string
      }
    ) {
      return fetcher.get<IPaginationResponse<INewsListItem>>(
        `/api/news/journalist/${groupId}/news`,
        {
          params: q,
        }
      )
    },
    detailNews(groupId: string, newsId: number) {
      return fetcher.get<INewsDetailForm>(
        `/api/news/journalist/${groupId}/news/${newsId}`
      )
    },
    createNews(groupId: string, news: FormData) {
      return fetcher.post<{}>(
        `/api/news/journalist/${groupId}/news/create`,
        news
      )
    },
    editNews(groupId: string, newsId: number, news: FormData) {
      return fetcher.post<{}>(
        `/api/news/journalist/${groupId}/news/${newsId}/edit`,
        news
      )
    },
    approveNews(groupId: string, newsId: number) {
      return fetcher.post(
        `/api/news/journalist/${groupId}/news/${newsId}/approve`
      )
    },
    changeStatusNews() {},
    deleteGroup(groupId: string) {
      return fetcher.delete(`/api/news/journalist/${groupId}`)
    },
    uploadImage(groupId: string, formData: FormData) {
      return fetcher.post(`/api/news/journalist/${groupId}/upload`, formData)
    },
  },
  listCategory(search?: string) {
    return fetcher.get<INewsCategory[]>(`/api/news/categories`, {
      params: {
        search,
      },
    })
  },
  createCategory(data: ICreateNewsCategoryBody) {
    return fetcher.post<{}>(`/api/news/categories`, data)
  },
  editCategory(categoryId: string, data: IEditNewsCategoryBody) {
    return fetcher.put<{}>(`/api/news/categories/${categoryId}`, data)
  },
  deleteCategory(categoryId: string) {
    return fetcher.delete<{}>(`/api/news/categories/${categoryId}`)
  },
  detailCategory(categoryId: string) {
    return fetcher.get<INewsCategory>(`/api/news/categories/${categoryId}`)
  },

  // -- home page
  search(q: INewsSearchQuery) {
    return fetcher.get<IPaginationResponse<INewsCard>>(`/api/news/search`, {
      params: {
        ...q,
        page: Math.max(q.page, 1),
        langID: q.langID.join(','),
        categoryID: q.categoryID.join(','),
      },
    })
  },
  hot() {
    return fetcher.get<INewsCard[]>(`/api/news/hot`)
  },
  latest(exclude: number[], limit = 10) {
    return fetcher.get<INewsCard[]>(`/api/news/latest`, {
      params: {
        exclude: exclude.join(','),
        limit,
      },
    })
  },
  hit(exclude: number[], limit = 10) {
    return fetcher.get<INewsCard[]>(`/api/news/hit`, {
      params: {
        exclude: exclude.join(','),
        limit,
      },
    })
  },

  latestComment() {
    return fetcher.get<INewsCommentCard[]>(`/api/news/comments/latest`)
  },

  /*
    normal user can use this api
     */
  listCountryCode(search?: string) {
    return fetcher.get<INewsCountry[]>(`/api/news/countries`, {
      params: {
        search,
      },
    })
  },
  // admin
  createCountryCode(country: ICreateNewsCountryBody) {
    return fetcher.post<{}>(`/api/news/countries`, country)
  },
  findCountryCodeById(countryId: number) {
    return fetcher.get<INewsCountry>(`/api/news/countries/${countryId}`)
  },
  editCountryCode(countryId: number, country: ICreateNewsCountryBody) {
    return fetcher.put<{}>(`/api/news/countries/${countryId}`, country)
  },
  detailCountryCode(countryId: number) {
    return fetcher.get<{}>(`/api/news/countries/${countryId}`)
  },
  deleteCountryCode(countryId: number) {
    return fetcher.delete<{}>(`/api/news/countries/${countryId}`)
  },

  checkFollowJournalist(journalistUserId: string) {
    return fetcher.get<boolean>(
      `/api/news/journalist/follow/${journalistUserId}/me`
    )
  },
  followJournalist(journalistUserId: string) {
    return fetcher.post(`/api/news/journalist/follow/${journalistUserId}`)
  },
  JournalistUnFollow(journalistUserId: string) {
    return fetcher.delete(`/api/news/journalist/follow/${journalistUserId}`)
  },
  fetchJournalistFollower(journalistUserId: string) {
    return fetcher.get<string[]>(
      `/api/news/journalist/follow/${journalistUserId}/follower`
    )
  },

  findByCategoryName(name: string, q: IPaginationRequest) {
    return fetcher.get<IPaginationResponse<INewsCard>>(
      `/api/news/all/category/${name}`,
      {
        params: q,
      }
    )
  },
  findByTagSlug(slug: string, q: IPaginationRequest) {
    return fetcher.get<IPaginationResponse<INewsCard>>(
      `/api/news/all/tag/${encodeURIComponent(slug)}`,
      {
        params: q,
      }
    )
  },

  me: {
    favorite() {
      return fetcher.get<IPaginationResponse<INewsCard>>(
        `/api/news/me/favorite`
      )
    },
  },

  slug: {
    detail(slug: string) {
      return fetcher.get<INewsDetail>(`/api/news/${encodeURIComponent(slug)}`)
    },
    postComment(slug: string, text: string, replyId?: number) {
      return fetcher.post<boolean>(
        `/api/news/${encodeURIComponent(slug)}/comments`,
        {
          comment: text,
          replyId,
        }
      )
    },
    fetchUserInteraction(slug: string) {
      return fetcher.get<INewsUserInteraction>(
        `/api/news/${encodeURIComponent(slug)}/interactions`
      )
    },
    feeling(slug: string, feeling: NewsFeelingEnum) {
      return fetcher.post(
        `/api/news/${encodeURIComponent(slug)}/interactions/feelings`,
        {
          feeling,
        }
      )
    },
    kiss(slug: string) {
      return fetcher.post(
        `/api/news/${encodeURIComponent(slug)}/interactions/kiss`
      )
    },
    love(slug: string) {
      return fetcher.post(
        `/api/news/${encodeURIComponent(slug)}/interactions/love`
      )
    },
    vote(slug: string) {
      return fetcher.post(
        `/api/news/${encodeURIComponent(slug)}/interactions/vote`
      )
    },
    favorite(slug: string) {
      return fetcher.post(
        `/api/news/${encodeURIComponent(slug)}/interactions/favorite`
      )
    },
    fetchRelated(slug: string) {
      return fetcher.get<INewsCard[]>(
        `/api/news/${encodeURIComponent(slug)}/related`
      )
    },
    fetchComments(slug: string) {
      return fetcher.get<INewsCommentCard[]>(
        `/api/news/${encodeURIComponent(slug)}/comments`
      )
    },
    addView(slug: string) {
      return fetcher.post(`/api/news/${encodeURIComponent(slug)}/views`)
    },
  },
}
