import { FaRegCircle } from 'react-icons/fa'
import MenuWithSubmenu from './MenuWithSubmenu'
import MenuWithoutSubmenu from './MenuWithoutSubmenu'
import { ReactNode } from 'react'

interface MenuProps {
  icon?: ReactNode
  label: string
  href?: string
  prefix?: string
  subMenu?: {
    icon?: ReactNode
    label: string
    href: string
  }[]
}

const Menu: React.FC<React.PropsWithChildren<MenuProps>> = ({
  icon = <FaRegCircle />,
  label,
  href,
  prefix = '/',
  subMenu = [],
}) => {
  return (
    <>
      {subMenu.length === 0 ? (
        <MenuWithoutSubmenu icon={icon} label={label} href={href as string} />
      ) : (
        <MenuWithSubmenu
          icon={icon}
          label={label}
          prefix={prefix}
          subMenu={subMenu}
        />
      )}
    </>
  )
}

export default Menu
