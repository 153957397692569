import { compose, withHandlers, withProps } from '@moxga/utils'
import { useUserDetailContext } from 'pages/users/contexts/UserDetailContext'
import { Table, Badge, Button } from '@moxga/ui-components'
import {
  CheckPermission,
  getPermissions,
  IPermissionDetail,
  IUser,
  UserBackofficeService,
  UserPermissionEnum,
} from '@moxga/services'
import withPermission from 'hoc/withPermission'

interface IPermissionByUser extends IPermissionDetail {
  hasPermission: boolean
}

interface Props {
  permissionsByUser: IPermissionByUser[]
  handleRemovePermission: (permission: UserPermissionEnum) => void
  handleAddPermission: (permission: UserPermissionEnum) => void
  isLoading: boolean
}

const UserPermission: React.FC<React.PropsWithChildren<Props>> = ({
  permissionsByUser,
  handleRemovePermission,
  handleAddPermission,
  isLoading,
}) => {
  return (
    <div className="mb-5">
      <h4 className="text-md mb-3 font-bold">Permissions</h4>
      <Table
        isLoading={isLoading}
        headers={[
          {
            label: 'Permission name',
            key: 'name',
          },
          {
            label: 'Has permission',
            key: 'hasPermission',
            render: (hasPermission: boolean) => {
              return (
                <>
                  {hasPermission ? (
                    <Badge variant="success">Yes</Badge>
                  ) : (
                    <Badge variant="danger">No</Badge>
                  )}
                </>
              )
            },
          },
          {
            label: 'Actions',
            key: 'id',
            render: (id: UserPermissionEnum, _, { hasPermission }) => {
              return (
                <>
                  {hasPermission ? (
                    <Button
                      size="sm"
                      variant="flat"
                      color="red"
                      onClick={() => handleRemovePermission(id)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="flat"
                      color="green"
                      onClick={() => handleAddPermission(id)}
                    >
                      Add
                    </Button>
                  )}
                </>
              )
            },
          },
        ]}
        data={permissionsByUser}
      />
    </div>
  )
}

export default compose(
  withPermission({
    noPermissionsRenderNothing: true,
    requiredPermissions: [UserPermissionEnum.ADMINISTRATOR],
  }),
  withProps(() => {
    const { userDetail, refetch, isLoading } = useUserDetailContext()
    const permissions = getPermissions()

    const permissionsByUser = permissions.map(permission => {
      const hasPermission = CheckPermission(userDetail?.permission!, [
        permission.key,
      ])

      return {
        ...permission,
        hasPermission,
      }
    })

    return { userDetail, permissionsByUser, refetch, isLoading }
  }),
  withHandlers({
    handleRemovePermission:
      ({ userDetail, refetch }: { userDetail: IUser; refetch: () => void }) =>
      async (permission: UserPermissionEnum) => {
        await UserBackofficeService.removePermission(userDetail.id, permission)
        refetch()
      },

    handleAddPermission:
      ({ userDetail, refetch }: { userDetail: IUser; refetch: () => void }) =>
      async (permission: UserPermissionEnum) => {
        await UserBackofficeService.addPermission(userDetail.id, permission)
        refetch()
      },
  })
)(UserPermission)
