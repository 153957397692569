import {
  IButton,
  TButtonColor,
  TButtonSize,
  TButtonVariant,
} from '../../interfaces/button'
import styled, { useTheme } from 'styled-components'

import { ITheme } from '../../interfaces/theme'
import { defaultTheme } from '../../configs/theme'
import { useMemo } from 'react'
import { RefreshIcon } from '@heroicons/react/outline'

const Button: React.FC<React.PropsWithChildren<IButton>> = ({
  type = 'button',
  variant = 'default',
  color = 'primary',
  textColor = 'white',
  rounded = false,
  size = 'md',
  fullWidth = false,
  startIcon,
  endIcon,
  children,
  onClick,
  disabled = false,
  style,
  loading = false,
}) => {
  let buttonStyleProps = useMemo(() => {
    return {
      variant,
      color,
      textColor,
      rounded,
      size,
      fullWidth,
    }
  }, [variant, textColor, color, rounded, size, fullWidth])

  return (
    <ButtonStyle
      {...buttonStyleProps}
      type={type}
      style={style}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading && (
        <RefreshIcon
          width={16}
          height={16}
          style={{
            marginRight: '10px',
          }}
          className="btn-loading"
        />
      )}
      {children}
    </ButtonStyle>
  )
}

interface buttonStyleProps {
  variant: TButtonVariant
  color: TButtonColor | string
  textColor: string
  rounded: boolean
  size: TButtonSize
  fullWidth: boolean
}

const ButtonStyle = styled.button<buttonStyleProps>`
  cursor: pointer;
  border-radius: ${props => (props.rounded ? '9999px' : '5px')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-loading {
    animation: spin 300ms linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${props => buttonStyleSizes[props.size]};

  ${props => ButtonStyles(props).default}
  &:hover:enabled {
    ${props => ButtonStyles(props).hover}
  }

  &:disabled {
    ${props => ButtonStyles(props).disabled}
  }
`

const buttonStyleSizes = {
  sm: {
    'font-size': '0.8rem',
    padding: '8px 19px',
  },
  md: {
    'font-size': '1rem',
    padding: '10px 22px',
  },
  lg: {
    'font-size': '1.2rem',
    padding: '13px 28px',
  },
  xs: {
    'font-size': '0.8rem',
    padding: '6px 16px',
  },
}

const ButtonStyles = (buttonStyle: buttonStyleProps) => {
  const theme: ITheme = useTheme()
  const color: string =
    theme?.color?.[buttonStyle.color as TButtonColor] ||
    (defaultTheme.color?.[buttonStyle.color as TButtonColor] as string) ||
    buttonStyle.color
  switch (buttonStyle.variant) {
    case 'outlined':
      return {
        default: {
          border: `1px solid ${color}`,
          background: 'transparent',
          color,
        },
        hover: {
          background: `${color}10`,
        },
        disabled: {
          color: `${color}99`,
          border: `1px solid ${color}99`,
        },
      }
    case 'flat':
      return {
        default: {
          border: 'none',
          background: 'transparent',
          color,
        },
        hover: {
          background: `${color}10`,
        },
        disabled: {
          color: `${color}99`,
        },
      }
    case 'gradient':
      return {
        default: {
          border: 'none',
          background: `linear-gradient(45deg, ${color} 0%, ${color}90 100%)`,
          color: buttonStyle.textColor,
        },
        disabled: {
          background: `linear-gradient(45deg, ${color}99 0%, ${color}90 100%)`,
        },
      }
    case 'relief':
      return {
        default: {
          border: 'none',
          background: color,
          color: buttonStyle.textColor,
          '-moz-box-shadow': 'inset 0px -3px 0px 0px rgba(0, 0, 0, 0.2)',
          '-webkit-box-shadow': 'inset 0px -3px 0px 0px rgba(0, 0, 0, 0.2)',
          'box-shadow': 'inset 0px -3px 0px 0px rgba(0, 0, 0, 0.2)',
        },
        disabled: {
          background: `${color}99`,
        },
      }
    case 'default':
    default:
      return {
        default: {
          border: 'none',
          background: color,
          color: buttonStyle.textColor,
        },
        hover: {
          'box-shadow': `0px 0px 10px ${color}65`,
        },
        disabled: {
          background: `${color}99`,
        },
      }
  }
}

export default Button
