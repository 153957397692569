import { createElement } from 'react'
import hoistNonReactStatics from './hoist-non-react-statics'

import setDisplayName from './setDisplayName'
import wrapDisplayName from './wrapDisplayName'

const identity = Component => Component

const branch =
  (test, left, right = identity) =>
  BaseComponent => {
    const Branch = props => {
      if (test(props)) {
        return createElement(left(BaseComponent), props)
      }
      return createElement(right(BaseComponent), props)
    }

    hoistNonReactStatics(Branch, BaseComponent)

    if (process.env.NODE_ENV !== 'production') {
      return setDisplayName(wrapDisplayName(BaseComponent, 'branch'))(Branch)
    }

    return Branch
  }

export default branch
