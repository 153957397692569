import { useState, useEffect, useCallback, useRef } from 'react'
// @ts-ignore
import { Response } from '@moxga/services'

type ReturnType<T> = {
  data: T
  execute: () => void
  error: string | null
  isLoading: boolean
}

function useAsync<T>({
  asyncFn,
  initialData,
  fallbackResponseData,
  isImmediate = true,
}: {
  initialData?: T
  fallbackResponseData?: T
  isImmediate?: boolean
  asyncFn: () => Promise<Response<T>>
}): ReturnType<T> {
  const ref = useRef({ isCancelled: false })

  const [data, setData] = useState<T | undefined>(initialData)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setLoadingStatus] = useState(true)

  const execute = useCallback(() => {
    setLoadingStatus(true)
    setError(null)
    return asyncFn()
      .then(res => {
        if (!ref.current.isCancelled) {
          if (!res.success) {
            return Promise.reject(res?.message || 'unknown error')
          }
          setData(res.data)
        }
      })
      .catch(err => {
        if (!ref.current.isCancelled) {
          setData(fallbackResponseData)
          setError(err)
        }
      })
      .finally(() => {
        if (!ref.current.isCancelled) {
          setLoadingStatus(false)
        }
      })
  }, [asyncFn])

  useEffect(() => {
    if (isImmediate) {
      execute()
    }
  }, [isImmediate])

  // Set cancel status on un-mounted
  useEffect(() => {
    ref.current.isCancelled = false
    return () => {
      ref.current.isCancelled = true
    }
  }, [])

  return {
    data: data!,
    error,
    execute,
    isLoading,
  }
}

export default useAsync
