export type Response<T> =
  | {
      success: true
      message: string
      data: T
    }
  | {
      code: ErrorCodeEnum
      success: false
      message: string
      errors: any
    }
  | {
      code: ErrorCodeEnum.InvalidInput
      success: false
      message: string
      errors: {
        [key: string]: string
      }
    }

export enum ErrorCodeEnum {
  Unknown,
  InvalidInput,
  TryAgainLater,
  UnAuthorization,
  ResourceNotFound,
  AlreadyExists,
  Alert,
  PleaseContactSupport,
}

// pagination response
export type IPaginationResponse<T> = {
  currentPage: number
  data: T[]
  firstPage: boolean
  lastPage: boolean
  totalPages: number
}

// jwt token and refresh token
export type ITokenResponse = {
  expiresAt: number
  refreshToken: string
  token: string
}

export type INotificationResponse = {
  actorIcon: string
  actorName: string
  createdAt: string
  entityId: string
  entityType: number
  entityTypeMsg: string
  id: number
  imageUrl: string
  message: string
  read: true
  actor: {
    avatarUrl: string
    bio: string
    coverUrl: string
    displayName: string
    email: string
    facebookContact: string
    gender: number
    id: string
    instagramContact: string
    lastActiveAt: string
    username: string
  }
}
