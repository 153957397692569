import { Alert, Box, Button, Input, Table } from '@moxga/ui-components'
import { CheckPermission, NewsService } from '@moxga/services'
import {
  IJournalistGroupItem,
  UserPermissionEnum,
} from '@moxga/services/src/types'
import { useContext, useEffect, useState } from 'react'

import { AppStoreContext } from '../../store/AppStore/AppStore'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
// import Table from 'components/journalist/Table'
import toast from 'react-hot-toast'
import withAuth from 'hoc/withAuth'
function Skeleton() {
  return (
    <Box>
      <div className="w-full h-[300px] bg-gray-100/50  animate-pulse"></div>
    </Box>
  )
}
const JournalistPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [groups, setGroups] = useState<IJournalistGroupItem[]>([])
  const { user } = useContext(AppStoreContext)

  useEffect(() => {
    fetchGroups()
  }, [])

  const fetchGroups = async () => {
    const result = await NewsService.journalist.fetchGroups({
      search: '',
    })
    if (result.success) {
      //success case
      console.log(result)
      setGroups(result.data)
      setIsLoading(false)
      return
    } else {
      //failed case
      toast.custom(<Alert type="danger">{result.message}</Alert>)
      setIsLoading(false)
      return
    }
  }

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <Box>
      <div className="p-[20px] flex space-x-4 justify-end items-center">
        <span className="flex items-center">
          <span className="mr-4">Search: </span>
          <div>
            <Input placeholder="ค้นหา" />
          </div>
        </span>
        {/*TODO create a reuse function*/}
        {user &&
          CheckPermission(user.permission, [
            UserPermissionEnum.NEWS_CREATE_JOURNALIST_GROUP,
          ]) && (
            <Link to={'/journalist/group/create'}>
              <Button type="button" style={{ height: '39px' }}>
                Add New Group
              </Button>
            </Link>
          )}
      </div>
      <Table
        headers={[
          {
            label: 'No.',
            key: 'id',
            render: (_, index) => <span>{index + 1}</span>,
          },
          {
            label: 'Group name',
            key: 'name',
            render: (name, _) => (
              <span className="text-gray-900 font-bold">{name}</span>
            ),
          },
          {
            label: 'Created at',
            key: 'createdAt',
            render: value => (
              <span>{dayjs(value).format('DD/MM/YY HH:mm')}</span>
            ),
          },
          {
            label: 'NEws',
            key: 'id',
            thStyle: {
              textAlign: 'right',
            },
            tdStyle: {
              textAlign: 'right',
            },
            render: (value, _, item) => {
              if (!item.me)
                return <div className="text-gray-400 text-right">-</div>
              return (
                <div className="float-right">
                  <Link to={`/journalist/group/${value}/news`}>
                    <Button color="primary">Go to news</Button>
                  </Link>
                </div>
              )
            },
          },
          {
            label: 'Detail',
            key: 'id',
            thStyle: {
              textAlign: 'right',
            },
            tdStyle: {
              textAlign: 'right',
              float: 'right',
            },
            render: (value, _, item) => {
              if (!item.me)
                return (
                  <Button disabled color="secondary">
                    Manage
                  </Button>
                )
              return (
                <Link to={`/journalist/group/${value}`}>
                  <Button color="warning">Manage</Button>
                </Link>
              )
            },
          },
        ]}
        data={groups}
        isLoading={isLoading}
      />
    </Box>
  )
}

export default withAuth(JournalistPage)
