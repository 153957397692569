import { FaEllipsisV, FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { useEffect, useState } from 'react'

import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'

type MinTableItem = { id: string | number }

type Props<T extends MinTableItem> = {
  headers: {
    thStyle?: {}
    tdStyle?: {}
    render?: (value: any, index: number, item: any) => React.ReactNode
    key: keyof T
    label?: string
    style?: {}
  }[]
  item: any
  index: number
  isBottom: boolean
  actions?: {
    onDelete?: (item: any, index: number) => void
    onEdit?: (item: any, index: number) => void
    onViewDetail?: (item: any, index: number) => void
    others?: {
      icon: React.ReactNode
      label: string
      onClick: (item: any, index: number) => void
    }[]
  }
}

const Row = <T extends MinTableItem>({
  headers,
  item,
  index,
  isBottom,
  actions,
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    function checkOutSide(e: any) {
      if (document.getElementById(`data-${index}`)?.contains(e.target)) {
      } else {
        setIsOpen(false)
      }
    }

    window.addEventListener('click', checkOutSide)
    return () => window.removeEventListener('click', checkOutSide)
  }, [])

  return (
    <RowStyle isBottom={isBottom}>
      <>
        {headers.map((h, indexHeaders) => {
          if (h.render) {
            return (
              <td key={indexHeaders} className={'td-body'} style={h.tdStyle}>
                {h.render(item[h.key], index, item)}
              </td>
            )
          }
          return (
            <td key={indexHeaders} className={'td-body'} style={h.tdStyle}>
              <span>{item[h.key]}</span>
            </td>
          )
        })}
        {actions &&
          (actions.onDelete || actions.onEdit || actions.onViewDetail) && (
            <td className="td-body-actions">
              <button
                id={`data-${index}`}
                className="btn-actions"
                onClick={() => setIsOpen(isOpen => !isOpen)}
              >
                <FaEllipsisV />
                {isOpen && (
                  <div className="actions-box">
                    <ul className="actions-ul">
                      {actions.others &&
                        actions.others.map((otherItem, indexLi) => {
                          return (
                            <li
                              key={indexLi}
                              className="actions-li"
                              onClick={() => otherItem.onClick(item, index)}
                            >
                              <span className="icon">{otherItem.icon}</span>
                              <span>{otherItem.label}</span>
                            </li>
                          )
                        })}
                      {actions?.onViewDetail && (
                        <li
                          className="actions-li"
                          onClick={() =>
                            actions.onViewDetail &&
                            actions.onViewDetail(item, index)
                          }
                        >
                          <span className="icon">
                            <FaEye />
                          </span>
                          <span>Detail</span>
                        </li>
                      )}
                      {actions?.onEdit && (
                        <li
                          className="actions-li"
                          onClick={() =>
                            actions.onEdit && actions.onEdit(item, index)
                          }
                        >
                          <span className="icon">
                            <FaPen />
                          </span>
                          <span>Edit</span>
                        </li>
                      )}
                      {actions.onDelete && (
                        <li
                          className="actions-li"
                          onClick={() =>
                            actions.onDelete && actions.onDelete(item, index)
                          }
                        >
                          <span className="icon">
                            <FaTrash />
                          </span>
                          <span>Delete</span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </button>
            </td>
          )}
      </>
    </RowStyle>
  )
}

interface RowStyleProps {
  isBottom: boolean
}

const RowStyle = styled.tr<RowStyleProps>`
  border: 1px solid ${defaultTheme['color-system']['border-table']};

  .td-body {
    padding: 10px 20px;
  }

  .td-body-actions {
    padding: 10px 20px;
    text-align: right;

    .btn-actions {
      position: relative;
      padding: 0 10px;

      .actions-box {
        position: absolute;
        right: 0;
        z-index: 99;
        ${props =>
          props.isBottom
            ? {
                bottom: '150%',
              }
            : {
                top: '150%',
              }}
      }

      .actions-ul {
        min-width: 180px;
        padding: 5px 0px;
        text-align: left;
        border: 1px solid ${defaultTheme['color-system']['border-table']};
        border-radius: 6px;
        background: white;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
          0 4px 6px -4px rgb(0 0 0 / 0.1);
        shadow-color: ${defaultTheme['color-system']['border-table']};

        .actions-li {
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:hover {
            background-color: ${props =>
              props.theme.color.primary || defaultTheme.color?.primary}20;
            color: ${props =>
              props.theme.color.primary || defaultTheme.color?.primary};
          }

          .icon {
            margin-right: 8px;
          }
        }
      }
    }
  }
`
export default Row
