import withAuth from 'hoc/withAuth'
import { compose, withProps } from '@moxga/utils'
import withPermission from 'hoc/withPermission'
import { UserPermissionEnum } from '@moxga/services'
import { UserDetailContextProvider } from '../contexts/UserDetailContext'
import { useParams } from 'react-router-dom'
import UserDetailPage from './components/UserDetailPage'

const UserDetailIndexPage = ({ userId }: { userId: string }) => {
  return (
    <UserDetailContextProvider userId={userId}>
      <UserDetailPage />
    </UserDetailContextProvider>
  )
}

export default compose(
  withAuth,
  withPermission({ requiredPermissions: [UserPermissionEnum.USER_READ] }),
  withProps(() => {
    const { userId } = useParams()
    return { userId }
  })
)(UserDetailIndexPage)
