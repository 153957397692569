import { ErrorCodeEnum, Response } from '../types/response'
import axios, { AxiosRequestConfig } from 'axios'

export const ACCESS_TOKEN_KEY = `access_token`
export const REFRESH_TOKEN_KEY = `refresh_token`

const instance = axios.create({
  baseURL:
    process.env.NEXT_PUBLIC_API_URL ||
    process.env.REACT_APP_API_URL ||
    'https://ikizzy.com/',
  timeout: 5 * 1000,
})

const makeError = (error: any): Response<any> => {
  return {
    success: false,
    code: ErrorCodeEnum.Unknown,
    message: error?.message || ``,
    errors: error,
  }
}

export const fetcher = {
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<Response<T>> {
    return instance
      .get<Response<T>>(url, config)
      .then(r => r.data)
      .catch(makeError)
  },
  delete<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<Response<T>> {
    return instance
      .delete(url, config)
      .then(r => r.data)
      .catch(makeError)
  },
  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Response<T>> {
    return instance
      .post<Response<T>>(url, data, config)
      .then(r => r.data)
      .catch(makeError)
  },
  put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Response<T>> {
    return instance
      .put<Response<T>>(url, data, config)
      .then(r => r.data)
      .catch(makeError)
  },
  patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Response<T>> {
    return instance
      .patch<Response<T>>(url, data, config)
      .then(r => r.data)
      .catch(makeError)
  },
  postForm<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Response<T>> {
    return instance
      .postForm<Response<T>>(url, data, config)
      .then(r => r.data)
      .catch(makeError)
  },
  putForm<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Response<T>> {
    return instance
      .putForm<Response<T>>(url, data, config)
      .then(r => r.data)
      .catch(makeError)
  },
  patchForm<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Response<T>> {
    return instance
      .patchForm<Response<T>>(url, data, config)
      .then(r => r.data)
      .catch(makeError)
  },
}

instance.interceptors.request.use(config => {
  // TODO: not support cookies
  if (typeof window !== 'undefined') {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
    if (accessToken) {
      config.headers = config.headers || {}
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
  }
  return config
})
instance.interceptors.response.use(
  r => {
    // TODO: handle jwt expire
    return r
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
