/*
 create or edit news page
 */
import { useEffect, useState } from 'react'
import { INewsCategory, INewsCountry, NewsService } from '@moxga/services'
import toast from 'react-hot-toast'
import { Alert } from '@moxga/ui-components'

const useNewsForm = (groupId: string) => {
  const [categories, setCategories] = useState<INewsCategory[]>([])
  const [countries, setCountries] = useState<INewsCountry[]>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    fetchData()
  }, [groupId])
  const fetchData = async () => {
    setLoading(true)
    const result1 = await NewsService.journalist.listCategory(groupId)
    if (result1.success) {
      //success case
      setCategories(result1.data)
    } else {
      //failed case
      toast.custom(<Alert type="danger">{result1.message}</Alert>)
    }

    const result2 = await NewsService.listCountryCode()
    if (result2.success) {
      //success case
      setCountries(result2.data)
    } else {
      //failed case
      toast.custom(<Alert type="danger">{result2.message}</Alert>)
    }
    setLoading(false)
  }

  return [{ categories, countries, loading }]
}

export default useNewsForm
