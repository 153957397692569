import * as Yup from 'yup'

import { Alert, Button, Input } from '@moxga/ui-components'
import { AuthService, ISignInBody } from '@moxga/services'

import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import withNotAuth from 'hoc/withNotAuth'

const { yupResolver } = require('@hookform/resolvers/yup')

const SignInPage = () => {
  let navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('จำเป็นต้องกรอก Username หรือ E-mail'),
    password: Yup.string().required('จำเป็นต้องกรอก Password'),
  })
  const formOptions = { resolver: yupResolver(validationSchema) }
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ISignInBody>(formOptions)
  const [loading, setLoading] = useState(false)

  const onSubmit = handleSubmit(async data => {
    setLoading(true)
    const result = await AuthService.signIn(data)
    setLoading(false)
    // success case
    if (result.success) {
      localStorage.setItem(`access_token`, result.data.token)
      localStorage.setItem(`refresh_token`, result.data.refreshToken)
      navigate('/dashboard')
      return
    }
    // failed case
    if (!result.success) {
      if (result.errors) {
        setError('email', { message: result.errors['email'] })
        setError('password', { message: result.errors['password'] })
      } else {
        // TODO: show toast
        toast.custom(<Alert type="danger">{result.message}</Alert>)
      }
    }
  })

  return (
    <>
      <div className="grid grid-cols-3 h-screen">
        <div className="relative hidden lg:flex lg:col-span-2 bg-[#F8F8F8] justify-center items-center">
          <img
            src="/images/common/logo.png"
            alt="logo"
            className="absolute h-[50px] top-[30px] left-[50px]"
          />
          <img src="/images/sign-in/cover.svg" alt="cover" className="w-3/4" />
        </div>
        <div className="flex col-span-3 lg:col-span-1 items-center justify-center p-[50px]">
          <div className="w-full max-w-[400px]">
            <img
              src="/images/common/moxga_logo.png"
              className="w-full md:w-3/4 mb-4 mx-auto"
              alt="wide-logo"
            />
            <form onSubmit={onSubmit} className="space-y-4">
              <Input
                config={{ ...register('email') }}
                label="Username"
                descriptionText={errors.email?.message}
                placeholder="Username"
                size="sm"
                isError={!!errors.email?.message}
              />
              <Input
                config={{ ...register('password') }}
                type="password"
                label="Password"
                descriptionText={errors.password?.message}
                placeholder="Password"
                size="sm"
                isError={!!errors.password?.message}
              />
              <Button type="submit" fullWidth loading={loading}>
                Sign in
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default withNotAuth(SignInPage)
