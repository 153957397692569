import { IUser } from '@moxga/services'
import { Box } from '@moxga/ui-components'
import { branch, compose, renderNothing, withProps } from '@moxga/utils'
import { useUserDetailContext } from 'pages/users/contexts/UserDetailContext'
import { memo } from 'react'
import UserPermission from './UserPermission'

interface Props {
  userDetail: IUser
}

const UserDetailPage: React.FC<React.PropsWithChildren<Props>> = ({ userDetail }) => {
  return (
    <Box>
      <div className="grid grid-cols-10 p-5">
        <div className="col-span-4">
          <h4 className="text-md mb-3 font-bold">User details</h4>
          <div className="grid grid-cols-10">
            <div className="col-span-2">
              <img
                src={userDetail.avatarUrl || 'https://i.pravatar.cc/300'}
                alt=""
                className="rounded-full w-20 h-20"
              />
            </div>
            <div className="col-span-8">
              <small>#{userDetail.id}</small>
              <h4 className="text-xl">{userDetail.displayName}</h4>
              <p>{userDetail.email}</p>
            </div>
          </div>
        </div>
        <div className="col-span-6">
          <UserPermission />
        </div>
      </div>
    </Box>
  )
}

export default compose(
  withProps(() => {
    const { isLoading, hasUserData, userDetail } = useUserDetailContext()

    return {
      isLoading,
      hasUserData,
      userDetail,
    }
  }),
  branch(
    ({ hasUserData }: { hasUserData: boolean }) => !hasUserData,
    renderNothing
  )
)(UserDetailPage)
