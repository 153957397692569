import { NewsService, UserPermissionEnum } from '@moxga/services'
import { Button } from '@moxga/ui-components'
import { compose, withHandlers, withProps } from '@moxga/utils'
import withPermission from 'hoc/withPermission'
import { NavigateFunction, useNavigate } from 'react-router-dom'

interface Props {
  handleRemoveCountry: () => void
}

const DeleteCountryButton: React.FC<React.PropsWithChildren<Props>> = ({ handleRemoveCountry }) => {
  return (
    <Button
      onClick={handleRemoveCountry}
      variant="outlined"
      size="sm"
      color="red"
    >
      Remove
    </Button>
  )
}

export default compose(
  withPermission({
    noPermissionsRenderNothing: true,
    requiredPermissions: [UserPermissionEnum.NEWS_COUNTRY_DELETE],
  }),
  withHandlers({
    handleRemoveCountry:
      ({
        countryId,
        countryName,
        handleReloadData,
      }: {
        countryId: number
        countryName: string
        handleReloadData: () => void
      }) =>
      async () => {
        if (
          // eslint-disable-next-line no-restricted-globals
          confirm(`Are you sure you wanted to delete country ${countryName}?`)
        ) {
          await NewsService.deleteCountryCode(countryId)

          handleReloadData()
        }
      },
  })
)(DeleteCountryButton)
