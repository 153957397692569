import Button from '../Button'
import Input from '../Input'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { TikTokEmbed } from 'react-social-media-embed'
import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'
import useModal from '../../services/useModal'

interface Props {
  label?: string
  url?: string | null
  urlDefault?: string | null
  onChangeTiktokURL: (url: string) => void
}

const Tiktok: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  url,
  urlDefault,
  onChangeTiktokURL,
}) => {
  const [urlDef, setURLDef] = useState(urlDefault)
  const { onOpenModal } = useModal()

  const onDeleteTiktok = () => {
    return Swal.fire({
      title: 'ต้องการลบ Tiktok ใช่ หรือไม่?',
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        onChangeTiktokURL('')
        setURLDef('')
      }
    })
  }

  return (
    <>
      {urlDef ? (
        <TikTokWrapper>
          {label && (
            <div className="label-wrapper">
              <label>{label}</label>
            </div>
          )}
          <div className="tiktok-wrapper">
            <label>TIKTOK</label>
            <div className="flex">
              <Button
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                }}
                onClick={() =>
                  onOpenModal({
                    header: 'Video from TIKTOK',
                    body: <TikTokEmbed url={url || ``} />,
                  })
                }
              >
                แสดงวิดีโอจาก Tiktok
              </Button>
              <Button
                color="danger"
                variant="outlined"
                onClick={onDeleteTiktok}
                style={{
                  marginLeft: '16px',
                }}
              >
                ลบวิดีโอจาก TIKTOK
              </Button>
            </div>
          </div>
        </TikTokWrapper>
      ) : (
        <Input
          label={label}
          config={{
            value: url || ``,
            onChange: e => onChangeTiktokURL(e.target.value),
          }}
          placeholder="https://cf.ikizzy.com/assets/media/demo.mp4"
          fullWidth
        />
      )}
    </>
  )
}

const TikTokWrapper = styled.div`
  width: 100%;

  .label-wrapper {
    margin-bottom: 8px;
  }

  .tiktok-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid
      ${props => props.theme.color.primary || defaultTheme.color?.primary};
    border-radius: 6px;
    padding: 10px;

    .input-tiktok-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
`

export default Tiktok
