import ModalStore, { ModalStoreContext } from './modalStore'
import { ReactNode, useEffect, useState } from 'react'

import Modal from '../Modal/Modal'

interface Props {
  children: ReactNode
}

const ModalProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const modalStore = ModalStore()

  return (
    <ModalStoreContext.Provider value={modalStore}>
      <Modal />
      {children}
    </ModalStoreContext.Provider>
  )
}

export default ModalProvider
