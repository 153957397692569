import hoistNonReactStatics from './hoist-non-react-statics'

import omit from './omit'
import pick from './pick'

import mapProps from './mapProps'
import setDisplayName from './setDisplayName'
import wrapDisplayName from './wrapDisplayName'

const { entries, keys } = Object

const mapKeys = (obj, func) =>
  entries(obj).reduce(
    (result, [key, value]) => ({
      ...result,
      [func(value, key)]: value,
    }),
    {}
  )

const renameProps = nameMap => BaseComponent => {
  const RenameProps = mapProps(props => ({
    ...omit(props, keys(nameMap)),
    ...mapKeys(pick(props, keys(nameMap)), (_, oldName) => nameMap[oldName]),
  }))(BaseComponent)

  hoistNonReactStatics(RenameProps, BaseComponent)

  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(BaseComponent, 'renameProps'))(
      RenameProps
    )
  }

  return RenameProps
}

export default renameProps
