import { Alert, Box, Button, Table } from '@moxga/ui-components'
import { Link, useNavigate } from 'react-router-dom'

import Avatar from '@moxga/ui-components/src/components/Avatar'
import {
  IJournalistMemberItem,
  UserPermissionEnum,
} from '@moxga/services/src/types'
import { useAppStoreContext } from 'store/AppStore/AppStore'
import { CheckPermission, NewsService } from '@moxga/services'
import toast from 'react-hot-toast'

interface Props {
  members: IJournalistMemberItem[]
  groupId: string
}

const MembersSection: React.FC<React.PropsWithChildren<Props>> = ({ members, groupId }) => {
  const { user } = useAppStoreContext()
  const navigate = useNavigate()
  const handleDeleteGroup = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`คุณแน่ใจ การกระทำดังกล่าวไม่สามารถย้อนกลับได้`)) {
      const r = await NewsService.journalist.deleteGroup(groupId)
      if (r.success) {
        navigate(`/journalist`)
      } else {
        toast.custom(<Alert type={'danger'}>{r.message}</Alert>)
      }
    }
  }

  return (
    <Box>
      <div className="flex justify-end space-x-4 p-4">
        {user &&
          CheckPermission(user.permission, [
            UserPermissionEnum.NEWS_DELETE_JOURNALIST_GROUP,
          ]) && (
            <Button color="danger" onClick={handleDeleteGroup}>
              ลบกลุ่มนี้
            </Button>
          )}
        <Link to={`member/invite`}>
          <Button color="primary">เพิ่มสมาชิก</Button>
        </Link>
      </div>
      <Table
        headers={[
          {
            label: 'Avatar',
            key: 'avatarUrl',
            render: (value, index, item) => (
              <Avatar imageURL={value} size="lg" />
            ),
          },
          {
            label: 'Display name',
            key: 'displayName',
          },
          {
            label: 'Level',
            key: 'level',
          },
        ]}
        data={members.map(member => {
          return {
            ...member.profile,
            id: member.profile?.id as string,
            level: member.levelClassMsg,
          }
        })}
      />
    </Box>
  )
}

export default MembersSection
