import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import ReactPaginate from 'react-paginate'
import { defaultTheme } from '../../configs/theme'
import styled from 'styled-components'

function Pagination({
  totalPage,
  onChange,
}: {
  totalPage: number
  onChange?: (page: number) => void
}) {
  const handlePageClick = (event: { selected: number }) => {
    if (onChange) {
      onChange(event.selected + 1)
    }
  }

  return (
    <PaginationWrapper>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<FaChevronRight />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPage}
        previousLabel={<FaChevronLeft />}
        // renderOnZeroPageCount={null}
      />
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 21px 14px;

  ul {
    width: auto;
    display: flex;
    background-color: #f3f2f7;
    border-radius: 15px;

    li {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;

      &:hover {
        color: ${props =>
          props.theme.color.primary || defaultTheme.color?.primary};
        transition: color 0.3s;
      }
    }

    .selected {
      background-color: ${props =>
        props.theme.color.primary || defaultTheme.color?.primary};
      color: #f3f2f7;
      font-weight: bold;

      &:hover {
        background-color: ${props =>
          props.theme.color.primary || defaultTheme.color?.primary};
        color: white;
      }
    }

    .disabled {
      color: #b9b9c3;

      &:hover {
        color: #b9b9c3;
      }
    }
  }
`

export default Pagination
