import { Box, Button } from '@moxga/ui-components'
import { compose, withHandlers, withProps } from '@moxga/utils'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { ExclamationIcon } from '@heroicons/react/outline'

const Unauthorized = ({ handleGoBack }: { handleGoBack: () => void }) => {
  return (
    <Box>
      <div className="flex flex-col justify-center items-center min-h-[50vh] w-full">
        <h1 className="mb-5">
          <ExclamationIcon />
          <span>403 | Unauthorized</span>
        </h1>
        <Button onClick={handleGoBack}>Back to Previous</Button>
      </div>
    </Box>
  )
}

export default compose(
  withProps(() => {
    const navigate = useNavigate()

    return {
      navigate,
    }
  }),
  withHandlers({
    handleGoBack:
      ({ navigate }: { navigate: NavigateFunction }) =>
      () => {
        navigate(-1)
      },
  })
)(Unauthorized)
