import { ITheme } from "../../interfaces/theme";
import { ReactNode } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { defaultTheme } from "../../configs/theme";

interface Props {
  children: ReactNode;
  theme?: ITheme;
}

const ThemeProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, theme = defaultTheme }) => {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;
