import withAuth from 'hoc/withAuth'
import withPermission from 'hoc/withPermission'
import { compose, withProps } from '@moxga/utils'
import {
  ErrorCodeEnum,
  ICreateNewsCategoryBody,
  INewsCategory,
  NewsService,
  UserPermissionEnum,
} from '@moxga/services'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Box, Button, Input } from '@moxga/ui-components'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import toast from 'react-hot-toast'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('จำเป็นต้องกรอกชื่อ Category'),
})

const NewsCategoryEditPage = ({ categoryId }: { categoryId: string }) => {
  const navigate = useNavigate()

  const [data, setData] = useState<INewsCategory | undefined>()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)

  const fetchData = () => {
    NewsService.detailCategory(categoryId)
      .then(res => {
        if (res.success) {
          setData(res.data)
        } else {
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError: setFormError,
  } = useForm<ICreateNewsCategoryBody>({
    resolver: yupResolver(validationSchema),
  })

  // did mount
  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {
    if (data) {
      setValue('name', data.name)
    }
  }, [data])

  if (loading) {
    return (
      <Box>
        <p>Loading...</p>
      </Box>
    )
  }
  if (!data || error) {
    return <Box>เกิดข้อผิดพลาด</Box>
  }

  const onSubmit = handleSubmit(_data => {
    console.log({
      name: _data.name,
      sortOrder: data,
    })
    setEditing(true)
    NewsService.editCategory(categoryId, {
      name: _data.name,
      sortOrder: data.sortOrder,
    })
      .then(r => {
        if (r.success) {
          toast.custom(<Alert type="success">แก้ไขหมวดหมุ่ข่าวสำเร็จ</Alert>)
        } else {
          toast.custom(<Alert type="danger">{r.message}</Alert>)
          if (r.code === ErrorCodeEnum.InvalidInput) {
            setFormError('name', r.errors['name'])
          }
        }
      })
      .finally(() => {
        fetchData()
        setEditing(false)
      })
  })

  return (
    <Box>
      <div className="p-5">
        <form onSubmit={e => onSubmit(e)}>
          <Input
            disabled={editing}
            placeholder="Category name"
            config={{ ...register('name') }}
            label="Category name"
            descriptionText={errors.name?.message}
            isError={!!errors.name?.message}
          />
          <div className="flex justify-between mt-10">
            <Button
              type="button"
              color={'secondary'}
              onClick={() => navigate(-1)}
            >
              Go back
            </Button>
            <Button disabled={editing} type={'submit'} color={'primary'}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </Box>
  )
}

export default compose(
  withAuth,
  withPermission({ requiredPermissions: [UserPermissionEnum.USER_READ] }),
  withProps(() => {
    const { categoryId } = useParams()
    return { categoryId }
  })
)(NewsCategoryEditPage)
